.mat-mdc-checkbox.mat-primary {
  --mdc-checkbox-selected-checkmark-color: white;
}

label.mdc-label {
  // override bootstrap "label" styles
  font-weight: inherit;
  display: initial;
  margin-bottom: initial;
}

.mat-mdc-checkbox-touch-target {
  // override default 48px touch target size to avoid overflow when
  // checkbox size default to 40px (--mdc-checkbox-state-layer-size: 40px;)
  // default target size defaults to 48px. this makes 4px of overflow when
  // checkbox is at the bottom of a scrollable container;
  height: 100%;
  width: 100%;
}
