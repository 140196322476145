.tc-table-page-header {
  position: sticky;
  top: -1px;
  z-index: 101; // gt mat-table header cells
  padding: 15px 30px;

  &.tc-sticky-header-pinned {
    background: #fff;
    box-shadow: 0 2px 3px rgba(0, 0, 0, 0.25);
  }

  .mat-mdc-raised-button+.mat-mdc-raised-button {
    margin-left: 15px;
  }

  .mat-mdc-button+.mat-button {
    margin-left: 2px;
  }
}

.tc-table-page-filters {
  padding: 15px 30px 30px;

  // we customize gaps between fields using grid-gap
  // this is possible because we don't use inline validation messages (yet?)
  .mat-mdc-form-field-subscript-wrapper::before { content: initial }

  // override default material form-field width
  .mat-form-field-type-mat-date-range-input .mat-mdc-form-field-infix,
  .mat-mdc-form-field-infix {
    width: 100px; // min-width
  }

  tc-select-search .mat-mdc-form-field,
  form>.mat-form-field {
    width: 100%;
  }
}

.tc-table-page-table {
  --tc-table-page-border: 1px solid #ddd;
  --tc-table-page-border-radius: 4px;

  display: block;
  margin: 0 30px;

  &:not(.borderless) {
    border: var(--tc-table-page-border);
    border-radius: var(--tc-table-page-border-radius);
  }
  // overflow: auto;

  // .mat-table-sticky {
  //   top: 97px !important; // tc-table-page-header height
  // }
}
