/*---- modalShipping ----*/
.modalShipping {
  .modal-content {
    box-shadow: 0px 1px 3px 0px rgba(0, 0, 0, 0.16);
    background: #fff;
    border-radius: 4px;
    border: 0;
  }

  .modal-header {
    padding: 22px 40px;
    border-bottom: 0;
  }

  .modal-header .modal-title {
    font-size: 18px;
    line-height: 30px;
    font-weight: 500;
    color: #202020;
  }

  .modal-header .modal-title img {
    max-width: 22px;
    margin-right: 12px;
    margin-top: -4px;
  }

  .modal-body {
    border-bottom: 0;
    padding: 22px 40px;
    background: #fafafa;
  }

  .nav-tabs {
    margin: -22px -40px 22px;
    padding: 0 44px;
    background: #fff;
    border-bottom: 1px solid #ddd;

    li {
      display: inline-block;
      list-style: none;
    }

    li a {
      font-size: 12px;
      line-height: 30px;
      font-weight: 500;
      color: #969696;
      padding: 12px 15px;
      text-transform: uppercase;
      display: block;
      border: 0;
    }

    li.active a,
    li a:hover {
      border-bottom: 3px solid #4899ee;
      color: #404040;
      text-decoration: none;
      border-left: none;
      border-right: none;
      border-top: none;
      background: none;
    }

    li a:focus {
      text-decoration: none;
    }
  }

  .pdl-0 {
    padding-left: 0;
  }

  .modal-footer {
    border-bottom: 0;
    padding: 23px 40px 28px;
    border-top: 1px solid #d6d6d6;

    .btn {
      font-size: 13px;
      line-height: 1.5;
      font-weight: 400;
      margin-left: 15px;
      text-transform: uppercase;
    }

    .btnCancel {
      color: #202020;
      background: none;
      padding: 8px 40px;
    }

    .btnCancel:hover {
      text-decoration: none;
      color: #3998ea;
    }

    .btnSave {
      color: #fff;
      background: #3998ea;
      border: 0;
      padding: 8px 15px;
    }

    .btnSave:hover {
      background: #178bee;
      color: #fff;
    }
  }
}


/*----- Media style ----*/

@media (min-width: 1390px) {
  .modalShipping .nav-tabs li a {
    font-size: 14px;
    padding: 12px 20px;
  }
}


@media (max-width: 768px) {
  .modalShipping .nav-tabs li {
    min-height: 58px;
  }

  .modalShipping .nav-tabs li a {
    padding: 12px;
    font-size: 10px;
  }
}

@media (max-width: 640px) {
  .modalShipping .nav-tabs li a {
    padding: 12px 10px;
  }

  .modalShipping .modal-footer .btn {
    font-size: 14px;
  }

  .modalShipping .modal-footer .btnSave {
    padding: 8px 40px;
  }
}


