tc-receipt-apply-payment-form {
  md-input-container.tc-md-block.with-prefix.md-input-has-value .input-prefix,
  md-input-container.tc-md-block.with-prefix.md-input-focused .input-prefix {
    display: block;
  }

  md-input-container.tc-md-block.with-prefix .input-prefix {
    color: #969696;
    position: absolute;
    left: 12px;
    bottom: 6px;
    z-index: 10;
    display: none;
  }

  md-input-container.tc-md-block.with-prefix .form-control {
    padding-left: 40px;
  }

  .form-group {
    &:not(:first-child) {
      padding-left: 0;
    }
  }
  .applied-item-actions {
    display: flex;
    align-items: center;
    a {
      margin-right: 1.5rem;
    }
    .bank-charge-icon-btn {
      font-size: 2rem;
      line-height: 50px;
    }
  }
}

