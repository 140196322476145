@import 'src/assets/styles/variables';

.redactor-toolbar {
  z-index: 1;
}

md-chips.tc-chips {
  .md-chips {
    border: 1px solid $tc-input-border-color;
    border-radius: 4px;
    box-shadow: none;
  
    &.md-focused {
      border: 1px solid $tc-blue;
      box-shadow: inset 0 1px 1px rgba(0, 0, 0, .075), 0 0 8px rgba(102, 175, 233, .6);
    }

    .md-chip-input-container input:not([type]) { // override material
      font-size: 14px;
      padding-left: 10px;
    }
  }
}

.tc-redactor-container .redactor-box {
  &:not(.redactor-source-view) {
    border: 1px solid $tc-input-border-color;
    border-radius: 4px;
  
    &.redactor-focus {
      border: 1px solid $tc-blue;
      box-shadow: inset 0 1px 1px rgba(0, 0, 0, .075), 0 0 8px rgba(102, 175, 233, .6);
    }
  }

  &.redactor-source-view {
    border: none;
    .redactor-statusbar { display: none; }
    .redactor-toolbar { background: transparent; }
    .md-input { float: none; }
  }

  .redactor-toolbar { position: absolute; top: 0; right: 0; }
  .redactor-in { min-height: 170px; padding: 24px 10px 6px 12px; }
  .redactor-source {
    line-height: 26px; // as md-input
    color: inherit;
  }
}

.md-tab {
  display: flex;
  align-items: center;
  place-content: space-between;
}