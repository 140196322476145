.ui-grid-cell:active,
.ui-grid-cell:focus {
  outline: none;
}

.fa-ellipsis-v {
  font-size: 20px;
  line-height: 40px; // row height, set in js
}

form[name="inputForm"] {
  input[ui-grid-editor] {
    margin: 10px 0;
  }
}
