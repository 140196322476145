
.dropdown-menu > li > .btn.btn-link {
  width: 100%;
  color: #333;
  text-align: left;
  padding: 3px 20px;
  clear: both;
  font-weight: normal;
  line-height: 1.42857;
  white-space: nowrap;

  &:hover, &:focus {
    text-decoration: none;
    color: #fff;
    background-color: #4899ee;
  }
}

.dropdown-submenu {
  position: relative;

  & > .dropdown-menu {
    top: 0;
    left: 100%;
    margin-top: -6px;
    margin-left: -1px;
    -webkit-border-radius: 0 6px 6px 6px;
    -moz-border-radius: 0 6px 6px;
    border-radius: 0 6px 6px 6px;
  }

  &:hover {
    & > .dropdown-menu {
      display: block;
    }

    & > a:after {
      border-left-color: #fff;
    }
  }

  & > a:after, & > .btn.btn-link:after {
    display: block;
    content: " ";
    float: right;
    width: 0;
    height: 0;
    border-color: transparent;
    border-style: solid;
    border-width: 5px 0 5px 5px;
    border-left-color: #ccc;
    margin-top: 5px;
    margin-right: -10px;
  }

  &.pull-left {
    float: none;
  }
  &.pull-left > .dropdown-menu {
    left: -100%;
    margin-left: 10px;
    -webkit-border-radius: 6px 0 6px 6px;
    -moz-border-radius: 6px 0 6px 6px;
    border-radius: 6px 0 6px 6px;
  }
}
