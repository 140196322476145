mat-form-field {
  --mdc-outlined-text-field-outline-color: rgba(0, 0, 0, 0.12);
  --mdc-outlined-text-field-label-text-weight: 400;
  --mat-form-field-container-text-weight: 400;
  --mat-select-trigger-text-weight: 400;

  // reduce default form field height
  .mat-mdc-text-field-wrapper.mdc-text-field--outlined .mat-mdc-form-field-infix {
    padding-top: 15px;
    padding-bottom: 11px;
    min-height: 50px;
  }

  .mat-mdc-text-field-wrapper {
    flex: none;
  }
}
html {
  .mdc-text-field .mdc-floating-label {
    --mat-mdc-form-field-label-transform: translateY(-75%) translateX(calc(1 * (42px + var(--mat-mdc-form-field-label-offset-x, 0px))));
    transform: translateY(-75%);
  }
}

.mdc-text-field--outlined {
  border-radius: var(--mdc-outlined-text-field-container-shape);
  background: white;

  // valid+focused outline shadow
  &:not(.mdc-text-field--disabled).mdc-text-field--focused .mdc-notched-outline {
    box-shadow: inset 0 1px 1px rgba(0, 0, 0, .075), 0 0 8px rgba(102, 175, 233, .6);
  }
  // invalid+focused outline shadow
  &:not(.mdc-text-field--disabled).mdc-text-field--focused.mdc-text-field--invalid .mdc-notched-outline {
    box-shadow: inset 0 1px 1px rgba(0, 0, 0, .075), 0 0 8px rgba(233, 102, 102, .6);
  }
}

.mat-mdc-form-field:has(input[readonly="true"]) {
  --mdc-outlined-text-field-hover-outline-color: var(--mdc-outlined-text-field-outline-color);
  // --mdc-outlined-text-field-outline-width: var(--mdc-outlined-text-field-outline-width);
}
