:host {
  --header-height: 56px;
  --row-height: 30px;

  html & td.mat-mdc-cell,
  html & tr.mat-mdc-row {
    padding-top: 5px;
    height: var(--row-height);
  }
}

cdk-virtual-scroll-viewport {
  height: calc(100vh - 480px);
  height: min(calc(100vh - 480px), calc(2px + var(--header-height) + var(--row-height) * var(--rows-visible)));
  width: 100%;
  display: block;
}

.mat-column-clone_sequence {
  width: 60px;
}

// .mat-column-origin_name{
// }
// .mat-column-shipment_dates{
// }
