.mdc-tab__text-label {
  text-transform: uppercase;
  min-width: initial !important;
  gap: 10px;
}

.mat-mdc-tab-nav-bar,
.mat-mdc-tab-header {
  border-bottom: 0;
}
