.tc-epoch-clear,
.tc-select-clear,
.tc-text-clear {
  .mat-icon {
    font-size: 18px;
    color: #777;
  }
}

.tc-text-clear {
  .mat-mdc-form-field & {
    position: absolute;
    top: calc(50% - 1em);
    right: -.8em;
  }
  .mat-mdc-form-field.tc-text-empty & {
    visibility: hidden;
  }
}

.tc-epoch-clear {
  .mat-mdc-form-field & {
    position: absolute;
    top: calc(50% - 1em);
    right: -.8em;
  }
  + .mat-datepicker-toggle,
  + .mtx-datetimepicker-toggle  {
    display: none;
  }
}

.tc-select-clear {
  .mat-mdc-form-field & {
    position: absolute;
    top: calc(50% - 1em);
    right: -.8em;
  }
}
.mat-mdc-select:not(.tc-select-empty) .mat-mdc-select-arrow-wrapper {
  display: none;
}
