.ui-grid:not(.scrollable) {
  height: auto !important;

  .ui-grid-viewport {
    height: auto !important;
  }
}

.ui-grid-filter-container {
  padding: 0 !important;
}

.ui-grid-selection-row-header-buttons {
  md-checkbox {
    margin-bottom: 0;
  }
}

.tc-multiline-cell > .ui-grid-cell-contents { white-space: initial; }
