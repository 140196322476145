.modal-footer {
  padding: 20px 30px;
}

// Default styles for most of the modal windows in this app
// TODO: rename this class and file. and review styles them selves.
//       some of this code might be obsolete
.modalclone {
  .modal-header {
    padding: 25px 35px 20px;
  }

  .modal-title {
    font-size: 18px;
    font-weight: 500;

    img {
      position: relative;
      top: -3px;
      margin-right: 10px;
    }
  }

  .modal-body {
    position: relative;
    padding: 30px;
    background: #fafafa;
  }

  .modal-footer {
    .btn {
      margin: 0 10px;
      padding: 8px 20px;
      font-size: 15px;
      font-weight: 400;
      text-transform: uppercase;
    }

    .btn-link {
      color: #202020;
    }

    .btn-link:hover,
    .btn-link:focus {
      color:#3998ea;
      text-decoration: none;
    }

    .btn-primary {
      background:#3998ea;
      color:#fff;
      border-color: transparent;
    }

    .btn-primary:hover {
      background:#178bee;
      color:#fff;
    }

    .btn-primary img {
      max-width: 18px;
      margin: -4px 4px 0 0;
    }
  }

  .checkbox input[type="checkbox"] {
    border: 2px solid #f00;
  }
}
