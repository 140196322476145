@import './variables';

.ng-invalid.ng-dirty {
  border-color: $tc-red !important;
  .has-error {
    color: $tc-red;
  }
}

.validation-invalid {
  color: $tc-red;
}
