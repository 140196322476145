.tbl-pagination {
  .page-chunk-btn {
    font-weight: 600;
  }

}

.dropdown-menu {
  z-index: 10151;
}


.tbl-pagination-holder {
  padding: 15px 0 0;
  font-weight: 400;
  font-size: 14px;
  color: #202020;
}

.tbl-pagination-holder .item-per-page {
  text-align: right;
}

.tbl-pagination-holder .item-per-page a,
.tbl-pagination-holder .item-per-page a:focus {
  color: #202020;
  text-decoration: none;
}

.tbl-pagination-holder .item-per-page a:hover {
  color: #4899ee;
  text-decoration: none;
}

.tbl-pagination-holder .item-per-page .dropdown-menu a:hover,
.tbl-pagination-holder .item-per-page .dropdown-menu a:focus {
  color: #fff;
  text-decoration: none;
}

.tbl-pagination ul li.descp {
  margin-right: 50px;
}

.tbl-pagination ul li.descp .fa {
  margin-left: 20px;
}

.tbl-pagination ul li.descp a {
  color: #202020;
}

.tbl-pagination ul li.descp a.disabled {
  color: #95989a;
  pointer-events: none;
}

.tbl-pagination ul li a {
  color: #ABABAB;
  text-decoration: none;
}

.tbl-pagination ul li a:hover {
  color: #4899ee;
}

.tbl-pagination ul li.active a,
.tbl-pagination ul li.active a:hover,
.tbl-pagination ul li.active a:focus {
  background: #4899ee;
  color: #fff;
  padding: 5px;
  border-radius: 2px;
  display: inline-block;
  min-width: 10px;
  font-size: 12px;
  font-weight: 700;
  line-height: 1;
  text-align: center;
  white-space: nowrap;
  vertical-align: middle;
}
