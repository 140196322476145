@import './variables';

.dropdown-menu-cell {
  overflow: visible;
  .ui-grid-cell-contents {
    overflow: visible;
    .dropdown {
      display: inline-block;
    }
  }
}

.block-elm {
  display: block;
}

.dropdown-menu > li > a:hover {
  color: #fff;
  background-color: #4899ee;
}

.dropdown-submenu {
  position: relative;
}

.dropdown-submenu > .dropdown-menu {
  top: 0;
  left: 100%;
  margin-top: -6px;
  margin-left: -1px;
  border-radius: 0 6px 6px 6px;
}

.dropdown-submenu:hover > .dropdown-menu {
  display: block;
}

.dropdown-submenu > a:after {
  display: block;
  content: " ";
  float: right;
  width: 0;
  height: 0;
  border-color: transparent;
  border-style: solid;
  border-width: 5px 0 5px 5px;
  border-left-color: #ccc;
  margin-top: 5px;
  margin-right: -10px;
}

.dropdown-submenu:hover > a:after {
  border-left-color: #fff;
}

.dropdown-submenu.pull-left {
  float: none;
}

.dropdown-submenu.pull-left > .dropdown-menu {
  left: -100%;
  margin-left: 10px;
  -webkit-border-radius: 6px 0 6px 6px;
  -moz-border-radius: 6px 0 6px 6px;
  border-radius: 6px 0 6px 6px;
}

.modal-xlg {
  @media (min-width: 992px) { width: 900px; }
  @media (min-width: 1242px) { width: 1180px; }
}

.modal-company-info .modal-lg {
  max-width: 1060px;
  width: inherit;
}

.modal-company-info .modal-full {
  max-width: 100%;
  width: inherit;
}

textarea {
  resize: vertical;
}


.modal-table {
  .bd-bottom {
    border-bottom: 1px solid #ddd;
  }
  .bd-none {
    border: none;
  }
  .row-head, .col-head {
    font-size: 15px;
    line-height: 30px;
    font-weight: 500;
    color: #939393;
    border-top: none;
  }
}

.modal-table-add {
  padding: 0;
  text-transform: uppercase;
  color: #969696;
  font-size: 15px;
  line-height: 30px;
  font-weight: 500;
  &:hover {
    text-decoration: none;
    color: #404040;
  }
}

// notification box
#toast-container > div {
  opacity: 1;
}


// moment date picker
.moment-picker {
  z-index: 10100;
}

.col-header-list {
  list-style: none;
  white-space: nowrap;
  overflow-x: scroll;
  padding: 15px;

  li, li.cdk-drag-preview {
    display: inline;
    padding: 10px 15px;
    background-color: #fff;
    margin-right: 5px;
    border: 1px solid #aaa;
    border-radius: 5px;

    &.dndPlaceholder {
      background-color: #cccccc;
      padding-left: 1px;
      padding-right: 1px;
    }
    &.dndDraggingSource {
      display: none;
    }
  }
}

.deal-status-submitted {
  background-color: #C0C409;
}
.deal-status-confirmed {
  background-color: #53CECE;
}
.deal-status-invoiced {
  background-color: #5969F4;
}
.deal-status-docsent {
  background-color: #E55DD0;
}
.deal-status-withcarrier {
  background-color: #D0DD46;
}
.deal-status-withreceiver {
  background-color: #EF8626;
}
.deal-status-paid {
  background-color: #7F0841;
}
.deal-status-closed {
  background-color: #357272;
}


%tc-portal-visible-container {
  position: relative;
  padding-right: 20px;
}

%tc-portal-visible-icon {
  display: inline-block;
  margin-left: 3px;
  color: $tc-label-font-color;
  font: normal normal normal .9em/1 'Font Awesome 6 Pro';
  content: '\F06E'; // eye icon
}

.ui-grid-cell-contents .label {
  display: inline-block;
  max-width: 100%;
}

.mat-mdc-cell .tc-portal-visible {
  @extend %tc-portal-visible-container;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  display: inline-block;

  &::after { @extend %tc-portal-visible-icon; }
}

.tc-portal-visible-contents {
  text-overflow: unset !important;
}

tc-select-search.tc-portal-visible .mat-mdc-select-value,
tc-select-search.tc-portal-visible .tc-select-search-readonly-value {
  &::after {
    @extend %tc-portal-visible-icon;
  }
}

.clear-input-container {
  .fa-xmark {
    z-index: 1040;
    display: inline-block;
    position: absolute;
    right: -4px;
    top: 18px;
    color: #9e9e9e;
  }

  input {
    padding: 6px 22px;
  }
}
