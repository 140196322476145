/*------------------------------------------------------------------
[Table of contents]
1.import fonts
1.import bootstrap-3.3.7
2.import navigation & side bar.
3.import pages
4. General part & helper classes.
-------------------------------------------------------------------*/
/*-----Import Fonts----*/
// @import url('https://fonts.googleapis.com/css?family=Roboto:100,100i,300,300i,400,400i,500,500i,700,700i,900,900i');

@import 'angular-moment-picker/dist/angular-moment-picker.css';
@import 'angular-moment-picker/dist/themes/material-ui.css';

@import 'md-date-range-picker/dist/md-date-range-picker.css';
// @import 'angular-ui-grid/ui-grid.css';
@import url('https://cdnjs.cloudflare.com/ajax/libs/selectize.js/0.8.5/css/selectize.bootstrap3.css');
@import '@lordfriend/nya-bootstrap-select/dist/css/nya-bs-select.css';

/*@import 'font-awesome.min.css';*/
/*!*-----Import Bootsrap----*!*/
/*@import 'bootstrap.min.css';*/
/*!*----Import Side bar---*!*/
/*@import 'side-menu.css';*/
/*!*----Import Pages---*!*/
/*@import 'page.cost-sheets.table.css';*/

@import './variables';
@import './components/nya-bs-select';
@import './components/box-control';
@import './components/form-badge';
@import './components/form-control';
@import './components/title-holder';
@import './components/moment-picker';
@import './components/md-date-range';
@import './components/ui-grid';
@import './components/md-radio';
@import './components/md-checkbox';
@import './components/md-tabs';
@import './components/loading-bar';
@import './components/modalshipping';
@import './components/modalclone';
@import './loading-overlay';
@import './components/typeahead';
@import './table-layout';
@import './skeleton-loader';
@import './clear-buttons';
@import './theme-variables';

/*-------General part & Helper classes------*/
html.md-default-theme, html, body.md-default-theme, body {
  background: none;
}

// Angular16 glitchy scroll rendering @see https://github.com/angular/components/issues/27249
.mat-mdc-form-field .mat-mdc-text-field-wrapper,
.mat-mdc-form-field .mdc-floating-label {
  will-change: auto;
}

body {
  background: #edf4f6;
  font-family: 'Roboto', sans-serif;
  color: #202020;
  margin: 0;
  padding: 0;
  overscroll-behavior-y: none; // disable scroll bounce
}

.main-body {
  padding: 30px 30px 0;
  background: unset !important;
}

.delete {
  color: #ea3939;
}

.text-ellipsis, .text-gray {
  display: block;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.btn-success,
.btn-primary,
.btn-danger {
  box-shadow: 2px 2px 6px 1px #ddd;
}

button.btn.btn-info {
  color: #fff;
  background: #88c1f2;
  border-color: #88c1f2;
}

button.btn.btn-info:hover {
  color: #fff;
  background: #4899ee;
  border-color: #4899ee;
}

.title-md {
  font-size: 20px;
  margin: 10px 0;
  font-weight: 400;
}

.wht-bg-container {
  background: #fff;
  padding: 15px;
  margin-bottom: 15px;
  border: 1px solid #ddd;
}

.nya-bs-select.menu-left .dropdown-menu
{
    right: 0;
    left: auto
}

.text-lightgray {
  color: lightgray
}

.text-gray {
  color: #919191;
}

.text-nowrap {
  white-space: nowrap;
}

.text-bold {
  font-weight: bold;
}

.text-red {
  color: red;
}

.label-warning {
  background-color: #f0ad4e;
}

.label-success {
  background-color: #58c264;
}
.label-success2 {
  background-color: adjust-color(#58c264, $lightness: 20%);
}

.label-scheduled {
  background-color: #02513c;
}

.media-body, .media-left, .media-right {
  display: table-cell;
  vertical-align: middle;
}

.panel.cs-default {
  margin-bottom: 20px;
  background-color: #fff;
  border: 1px solid #ddd;
  border-radius: 0;
}

.cs-default .panel-heading {
  padding: 15px 15px;
  border-bottom: 1px solid #ddd;
  background-color: #fff;
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}

.cs-default .panel-heading .p-title {
  font-size: 20px;
  font-weight: 500;
}

.cs-default .panel-heading .p-title .btn-danger {
  background: #ea3939;
  margin-left: 15px;
  border-color: transparent;
  font-size: 16px;
}

.cs-default .panel-footer {
  padding: 10px 15px;
  background-color: #fff;
  border-top: 1px solid #ddd;
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0;
}

select.ng-empty {
  color: $tc-placeholder-color;
}

.trccuspadding {
  padding-left: 9px;
  padding-right: 9px;
}

/*--- start of global input and select style--*/
.trc-group .form-control {
  box-shadow: none;
  height: 50px;
  border-color: $tc-input-border-color;
  color: #202020;
  margin-bottom: 0px;
}

.trc-group.setfield select.form-control, .trc-group select.form-control {
  padding-left: 14px;
  padding-right: 8px;
}

.trc-group.setfield .fieldtitle {
  position: absolute;
  top: 6px;
  left: 22px;
  font-size: $tc-label-font-size;
  font-weight: 400;
  color: $tc-label-font-color;
}

.trc-group.setfield.slctsetfield .form-control {
  padding-left: 12px;
}

@-moz-document url-prefix() {
  .trc-group.setfield.slctsetfield .form-control {
    padding-left: 8px;
  }
}

.trc-group.setfield .form-control {
  padding: 24px 12px 6px;
}

.trc-group.setfield.trc-pdl-0 .fieldtitle {
  left: 14px;
}

.btnapply,
.btnsend,
.btncancel {
  border: none;
  color: #fff;
  font-size: 18px;
  line-height: 30px;
  font-weight: 500;
  text-transform: uppercase;
  padding: 8px 30px;
  margin-top: 3px;
  box-shadow: 0px 3px 6px 0px rgba(0, 0, 0, 0.16);

  &:hover {
    border: none
  }
}

.btnapply,
.btnsend {
  background: #3998ea;
  &:active:focus,
  &:active,
  &:hover,
  &:focus {
    background: #178bee;
  }
}

.btncancel {
  background: #ea3939;
  &:active:focus,
  &:active,
  &:hover,
  &:focus {
    background: #f71111;
  }
}

/*--- end of global input and select style--*/

/*===============Responsive=============*/

@media (min-width: 768px) {
  .table-responsive {
    overflow-y: visible !important;
  }
}

@media (max-width: 768px) {
  .main-body {
    padding: 15px;
  }
  .header-breadcrumb-bar {
    margin-bottom: 0;
    padding-bottom: 0;
  }
  .nav-holder .list-inline > li {
    margin-left: 0;
    margin-right: 15px;
  }
  .title-md {
    font-size: 18px;
    // margin: 0 0 15px;
  }
}

.fill-hr {
  margin: 25px 0;
}

.dropdown-menu {
  .fa {
    height: 16px;
    width: 16px;
  }
}


.tc-card {
  box-shadow: 0px 1px 3px 0px rgba(0, 0, 0, 0.16);
}

.tc-toolbar {
  height: 60px;
  padding-right: 30px;
  padding-left: 30px;
  border-bottom: 1px solid #d6d6d6;
  box-sizing: border-box;
}

.tc-toolbar-title {
  font-size: 14px;
  font-weight: 400;
  text-transform: uppercase;

  .modal & {
    font-size: 20px;
    font-weight: 500;
    text-transform: none;
  }
}

.tc-content {
  padding: 30px;
}

.tc-squeeze-grid {
  [class*="col-"] {
    padding-right: 5px;
    padding-left: 5px;
  }

  .row {
    margin-right: -5px;
    margin-left: -5px;
  }
}


// we disabled "upload insurance" button
.tc-disabled {
  cursor: not-allowed;
  opacity: .5;
}

.md-dialog-container {
  z-index: 10100; // higher than .cdk-overlay-container
}

[uib-dropdown-menu].dropdown-menu {
  z-index: 10100 !important;
}


// style for the "advanced search" link
.btn-link-active {
  &,
  &:hover,
  &:focus,
  &:visited,
  &:active {
    color: RoyalBlue !important;
  }
}

// replacing a[href=#] with button.btn-link
.btn-link:not(.btn) {
  margin: 0;
  border: 0;
  padding: 0;
}

// add ellipsis to long buttons
.btn-overflow {
  text-overflow: ellipsis;
  max-width: 100%;
  overflow: hidden;
  text-align: left
}

// TODO: replace with mat-toolbar[color=white]
.tc-overlay-header {
  padding: 20px 30px;
  display: flex;
  place-items: center;

  .mat-headline-6 {
    flex: 1;
    margin-bottom: 0;
  }

  .btn + .btn {
    margin-left: 10px;
  }
}

