// .modal
.tc-shipment-rate-picker {
  .modal-dialog {
    @media (min-width: 768px) { min-width: calc(100% - 60px); }
    @media (min-width: 1024px) { min-width: calc(100% - 60px); }
    @media (min-width: 1280px) { min-width: 1200px; }
  }

  .modal-body {
    padding: 20px 30px 10px;
  }

  // hide currency
  .ui-grid small { display: none; }
  .modal-title { margin-bottom: 0; }
  .modal-header md-input-container { margin-left: 30px; }

  .ui-grid-header-cell-row { height: 40px; }
}

.ui-grid-row.ui-grid-row-selected {
  &:nth-child(even)>[ui-grid-row]>.ui-grid-cell.col-bg-blue { background-color: #aedae6 !important; }
  &:nth-child(odd)>[ui-grid-row]>.ui-grid-cell.col-bg-blue { background-color: #aedae6 !important; }

  &:hover {
    &:nth-child(even)>[ui-grid-row]>.ui-grid-cell.col-bg-blue { background-color: #f3f8fd !important; }
    &:nth-child(odd)>[ui-grid-row]>.ui-grid-cell.col-bg-blue { background-color: #f3f8fd !important; }
  }
}
