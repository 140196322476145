/*! Angular Moment Picker - v0.10.2 - http://indrimuska.github.io/angular-moment-picker - (c) 2015 Indri Muska - MIT */
.moment-picker .moment-picker-container {
  text-shadow: none;
  -webkit-border-radius: 0;
     -moz-border-radius: 0;
          border-radius: 0;
  padding: 0;
}
.moment-picker .header-view {
  border: 1px solid #fff;
}
.moment-picker .header-view th {
  background: #eee;
  -webkit-border-radius: 0;
     -moz-border-radius: 0;
          border-radius: 0;
  min-width: 40px;
  height: 40px;
}
.moment-picker .header-view th:hover,
.moment-picker td:hover {
  background-color: #e0e0e0;
}
.moment-picker td {
  -webkit-border-radius: 60px;
     -moz-border-radius: 60px;
          border-radius: 60px;
}
.moment-picker .moment-picker-specific-views {
  padding: 4px 8px;
}
.moment-picker .moment-picker-specific-views table {
  border-collapse: separate;
  border-spacing: 3px;
}
.moment-picker .moment-picker-specific-views th {
  background: none;
  cursor: default;
}
.moment-picker .decade-view td,
.moment-picker .year-view td {
  min-width: 60px;
  height: 60px;
}
.moment-picker .month-view td {
  min-width: 33px;
  height: 33px;
}
.moment-picker .day-view td,
.moment-picker .hour-view td {
  min-width: 60px;
  height: 40px;
}
.moment-picker .minute-view table {
  border-spacing: 2px;
}
.moment-picker .minute-view td {
  min-width: 40px;
  height: 40px;
}
