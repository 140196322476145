@import '../variables';
@import '../animations';
@import './box-control';

.form-control {
  @extend %box-control;

  font-size: $tc-input-font-size;
}


.setField .fieldTitle {
  position: absolute;
  top: 6px;
  left: 24px;
  color: $tc-label-font-color;
  font-size: $tc-label-font-size;
  font-weight: 400;
}

.lebelMargin {
  //margin-top: -19px;
}

.setField {
  .form-control {
    padding: 24px 12px 6px;
  }
  select.form-control {
    padding: 14px 12px 6px;
  }
}
