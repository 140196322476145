@import '../variables';
@import '../animations';
@import './box-control';
@import './shared/required-star';


// override styles from "node_modules/@lordfriend/nya-bootstrap-select/dist/css/nya-bs-select.css"
.flex,
[class*="col-"] {
  > .nya-bs-select:not([class*="col-"]):not([class*="form-control"]):not(.input-group-btn) {
    width: 100%;
  }
}

// .nya-bs-select.form-control.box-control {
.nya-bs-select {
  @extend %box-control;

  position: relative;
  width: 100%;

  // override bootstrap styles
  &.btn-group > .btn:hover {
    z-index: 1;
  }

  &.btn-group .dropdown-menu.inner{
    max-height: 300px;
    overflow: auto;
  }

  .dropdown-menu li > a {
    padding: 7px 30px 7px 20px;
  }

  .bs-searchbox {
    input.form-control {
      height: inherit;
    }
  }

  .btn {
    // border: none;
    // line-height: inherit;
    height: inherit;

    &,
    &:active,
    &:hover {
      background-color: $tc-white;
      box-shadow: none;
    }
  }

  .special-title {
    font-size: $tc-input-font-size;
  }

  &.hide-selected { // from custom.scss
    .dropdown-toggle {
      height: 50px;
      border-color: #e2e2e2;
      font-size: 13px;

      .filter-option {
        display: none !important;
      }

      .special-title {
        display: inline-block;
      }
    }
  }


  &.ng-not-empty > .dropdown-toggle .filter-option {
    width: calc(100%);
  }

  // TODO: this should go
  &.filled > .dropdown-toggle .filter-option {
    width: calc(100% - 20px);
  }

  &.ng-not-empty,
  &.filled {
    > .dropdown-toggle {
      .filter-option {
        position: relative;
        top: 8px;
        left: 0;
        font-size: $tc-input-font-size;
        text-align: left;
        text-overflow: ellipsis;
      }

      .special-title {
        display: inline-block;
        position: absolute;
        top: 4px;
        left: 11px;
        width: calc(100% - 11px);
        color: $tc-label-font-color;
        font-size: $tc-label-font-size;
        font-weight: 400;
        text-overflow: ellipsis;
      }
    }
  }

  // CUT: from deal form
  &.open {
    z-index: 2;
  }

  &.ng-empty {
    .special-title {
      color: $tc-placeholder-color;
    }
  }

  &[required] .special-title {
    @extend %required-star;
  }

  // invalid state
  // TODO: merge with validation
  .ng-submitted &.ng-invalid,
  &.ng-invalid.ng-dirty,
  &.ng-invalid.ng-touched {
    @include shake();

    .btn {
      border-color: $tc-invalid-border-color;
    }

    &:not(.ng-empty) .special-title {
      color: $tc-invalid-placeholder-color;
    }
  }
}

// CUT: from theme
// fix nya-bs-select styles
.filters-group .nya-bs-select {
  .dropdown-menu {
    li a { // scss-lint:disable SelectorDepth
      padding-right: 35px;
    }

    .selected .check-mark { // scss-lint:disable SelectorDepth
      margin-top: 2px;
    }
  }
}

.form-control .special-title {
  font-size: $tc-input-font-size;
}

// CUT: from deal form
.form-control[disabled],
.form-control[readonly],
fieldset[disabled] .form-control {
  &.nya-bs-select button.btn {
    &,
    &:hover {
      background-color: $tc-input-readonly-background;
    }
  }
}

// .nya-bs-select:not(.filled) .dropdown-toggle {
//   line-height: 30px;
// }
