.tc-dirty-badge,
.tc-invalid-badge {
  &::after {
    position: absolute;
    width: 7px;
    height: 7px;
    border-radius: 50%;
    content: '';
  }

  &.mat-mdc-button,
  &.mat-mdc-raised-button,
  &.mat-mdc-unelevated-button {
    &::after {
      top: 3px;
      right: 3px;
    }
  }

  // NOTE: remove after matched-offer-overlay upgrade
  &.btn-info,
  &.btn-primary,
  &.btn-success {
    position: relative;

    &::after {
      right: 7px;
      width: 7px;
    }
  }
}

.tc-invalid-badge::after { background: $tc-red; }
.tc-dirty-badge::after { background: $tc-white; }
.mat-mdc-button { &.tc-dirty-badge::after { background: $tc-blue; }}
