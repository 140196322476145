tc-file-select-wrap {
  button {
    position: relative;
    z-index: 1;
  }
  .file-select-holder {
    width: 0;
    height: 0;
    opacity: 0;
    overflow: hidden;
    position: relative;
    z-index: 0;
  }
}
