.mat-mdc-slide-toggle.mat-primary {
  --mdc-switch-selected-focus-state-layer-color: #4899ee;
  --mdc-switch-selected-handle-color: #4899ee;
  --mdc-switch-selected-hover-state-layer-color: #4899ee;
  --mdc-switch-selected-pressed-state-layer-color: #4899ee;
  --mdc-switch-selected-focus-handle-color: #307ce7;
  --mdc-switch-selected-hover-handle-color: #307ce7;
  --mdc-switch-selected-pressed-handle-color: #307ce7;
  --mdc-switch-selected-focus-track-color: #4899ee;
  --mdc-switch-selected-hover-track-color: #4899ee;
  --mdc-switch-selected-pressed-track-color: #4899ee;
  --mdc-switch-selected-track-color: #4899ee;
}
