@import './variables';

.page-new-cost-sheet {
  padding: 15px 0;
  margin-bottom: 50px;
}

.page-new-cost-sheet .pdl-0{
  padding-left: 0;
}


.page-new-cost-sheet .tab-pane{
  min-height: 250px;
}


/*----------------------------------------
    Start 02-Nov-2017
----------------------------------------*/

.block {
  width:100%;
  float:left;
}

.wht-bg-container.costSheet-tabCont {
  padding:15px 0 0;
}

.fillHR100 {
  width: 100%;
  display: block;
  float: left;
  height: 1px;
  background: #d2d2d2;
  border: 0;
  margin-bottom: 0px;
  margin-top: 0px;
}

@media (max-width: 768px) {
  .page-new-cost-sheet .pdl-0 {
  padding-right: 0; // RLY ?!
  }
}
