.mrg-0 {
  margin: 0;
}

.pdg-0 {
  padding: 0;
}

.tc-shipping-details-menu {
  padding: 0;
  position: relative;
  width: 110px;

  ul {
    padding: 0;
    margin: 0;
    position: fixed;
  }

  ul li {
    list-style: none;
    display: block;
    margin-bottom: 5px;
  }

  ul li a {
    font-size: 14px;
    font-weight: 400;
    line-height: 30px;
    color: #202020;
    padding-bottom: 2px;
  }

  ul li.active a {
    font-weight: 500;
    border-bottom: 3px solid #4899ee;
    text-decoration: none;
  }

  ul li a:hover,
  ul li a:focus {
    color: #4899ee;
    text-decoration: none;
    outline: none;
  }
}

.checkbox-holder md-checkbox {
  &.md-default-theme.md-checked,
  &.md-checked {
    .md-icon {
      border-color: transparent;
      background-color: #4899ee;
    }
  }
}

.tc-shipping-details-body {
  .wht-bg-container {
    padding: 0 40px;

    &.deal-info,
    &.products,
    &.invoices,
    &.secondary-cost,
    &.shipping-summary,
    &.documents,
    &.summary {
      box-shadow: 0px 1px 3px 0px rgba(0, 0, 0, 0.16);
      border: 0;
    }

    &.products,
    &.documents,
    &.invoices,
    &.secondary-cost,
    &.shipping-summary {
      padding-bottom: 40px;
    }

    &.products,
    &.documents,
    &.invoices,
    &.secondary-cost,
    &.shipping-summary {
      .title {
        margin-bottom: 30px
      }
    }

    &.documents {
      padding-right: 0;
      padding-left: 0;
    }

    &.deal-info {
      padding-bottom: 40px - 8px;
    }
  }

  .title {
    background: #fff;
    margin: 0 -40px 50px;
    padding: 15px 38px;
    border-bottom: 1px solid #d6d6d6;
    font-size: 14px;
    font-weight: 500;
    text-transform: uppercase;
    font-weight: 400;
    line-height: 30px;
    color: #202020;
  }

  .secondary-cost {
    padding-bottom: 46px; // remove?
  }
}


.shipping-page .modal {
  z-index: 10500;
}

.shipping-page .modal-backdrop {
  z-index: 10400;
}

@media (min-width: 1390px) {
  .shipping-page .title-md {
    font-size: 20px;
  }
}

@media (max-width: 1389px) {
  .shipping-page .title-md {
    font-size: 20px;
  }
}

@media (max-width: 1290px) {
  .page-new-cost-sheet.shipping-page .setField .fieldTitle {
    font-size: 10px;
  }

  .shipping-page .tc-shipping-details-body .title {
    padding: 15px 20px;
  }
}

@media (max-width: 1024px) {
  .table-responsive {
    border: 0;
  }

  .page-new-cost-sheet.shipping-page .deal-info > .pdg-0 {
    width: 100%;
  }

  .page-new-cost-sheet.shipping-page .secondary-cost .form-group {
    width: 33.33%;
  }
}

@media (max-width: 991px) and (min-width: 768px) {
  .tc-shipping-details-menu ul li a {
    font-size: 12px;
  }
}

@media (max-width: 768px) {
  .tc-shipping-details-menu {
    position: absolute;
    top: -10px;
    left: 0;
    width: 100%;
    z-index: 1000;
  }

  .tc-shipping-details-menu ul {
    position: fixed;
    width: 100%;
    padding: 10px 0;
    background: #f1f1f3;
  }

  .tc-shipping-details-menu ul li {
    display: inline-block;
    margin-bottom: 0;
    padding: 0 8px;
  }

  .tc-shipping-details-menu ul li a {
    font-size: 12px;
  }
}

@media (max-width: 640px) {
  .page-new-cost-sheet.shipping-page .secondary-cost .form-group {
    width: 100%;
  }
}

@media (max-width: 440px) {
  .tc-shipping-details-menu ul li a {
    font-size: 10px;
  }
}
