.breadcrumb,
a.breadcrumb {
  background: none;
  padding: 0;
  margin-bottom: 0;
  font-size: 13px;

  // .goBack {
  //   color: #202020;
  // }

  &,
  &:active,
  &:focus {
    color: #919191;
    text-decoration: none;
  }

  &:hover {
    color: #4899ee;
    text-decoration: none;
  }

  &:last-of-type {
    color: #202020;
  }

  & + .breadcrumb:before {
    font-family: 'Font Awesome 6 Pro';
    padding: 0 5px;
    color: #202020;
    font-size: 15px;
    content: "\f105";
  }
}
