/*
* Name: md-date-range-picker
* Version: 1.0.0
* Build Date: 5/27/2021
* Author: roel barreto <greatcodeideas@gmail.com>
*/
md-date-range {
    cursor: pointer;
}
md-date-range-picker {
    display: block;
    max-width: 664px;
}
md-date-range-picker .md-date-range-picker__one-panel {
    display: block;
    max-width: 340px;
}
.md-date-range-picker [event-key] *{
    pointer-events: none;
}
.md-date-range-picker {
    position: relative;
    background: #FFF;
}

.md-date-range-picker__title {
    text-align: center;
    font-size: 1rem;
    background: rgb(16, 108, 200);
    color: #FFF;
    margin: 0;
    padding: 16px 0;
}

.md-date-range-picker__month-year {
    text-align: center;
    font-size: 1rem;
    background: #eee;
    margin: 0;
    padding: 0 0;
}

.md-date-range-picker__month-year .md-select-icon {
    display: none;
}

.md-date-range-picker__month-year md-select {
    margin: 10px 0;
}

.md-date-range-picker__templates {
    background: #EEE;
}

.md-date-range-picker__calendar-wrapper {
    padding: 0 0;
    width: 308px;
    min-width: 308px;
}

.md-date-range-picker__week {
    background: #e0e0e0;
}

.md-date-range-picker__calendar__grid {
    font-size: 13px;
    color: #222;
    padding: 0;
    display: inline-block;
    width: 44px;
    height: 44px;
    line-height: 44px;
    text-align: center;
    position: relative;
    outline: none;
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    border-radius: 50%;
    background: rgba(2550, 255, 255, 0);
}

.md-date-range-picker__calendar .md-date-range-picker__calendar__grid {
    cursor: pointer;
}

.md-date-range-picker__calendar__not-in-active-month {
    color: rgba(0,0,0,0.5);
}

.md-date-range-picker__calendar 
.md-date-range-picker__calendar__grid:hover:not(.md-date-range-picker__calendar__selected):not(.md-date-range-picker__calendar__disabled) {
    transition: background 300ms ease-out;
    background: rgba(0, 0, 0, 0.1);
}

.md-date-range-picker__calendar__selected {
    background: rgb(16, 108, 200);
    color: #FFF;
    border-radius: 0;
}
.md-date-range-picker__calendar__selected.md-date-range-picker__calendar__disabled {
    background: rgba(0, 0, 0, 0.2);
}
.md-date-range-picker__calendar__not-in-active-month.md-date-range-picker__calendar__selected {
    color: rgba(255,255,255,0.2);
}

.md-date-range-picker__calendar__today {
    font-weight: bold;
}

.md-date-range-picker__calendar 
.md-date-range-picker__calendar__grid.md-date-range-picker__calendar__disabled {
    opacity: 0.3;
    cursor: not-allowed;
}

.md-date-range-picker__calendar__start {
    border-top-left-radius: 50%;
    border-bottom-left-radius: 50%;
}

.md-date-range-picker__calendar__end {
    border-top-right-radius: 50%;
    border-bottom-right-radius: 50%;
}

.md-date-range-picker__select {
    z-index: 100;
}

.md-date-range-picker__calendar__selection {
    pointer-events: none;
}