@import 'src/assets/styles/variables';

.ui-grid {
  font-size: 13px;
  border: 0px solid #dddddd;
}

.ui-grid-header-cell:not(:last-child) .ui-grid-vertical-bar,
.ui-grid-cell:not(:last-child) .ui-grid-vertical-bar {
  width: 0px;
}

.ui-grid-header-cell:not(:last-child) .ui-grid-vertical-bar {
  background-color: #ffffff;
}

.ui-grid-cell:not(:last-child) .ui-grid-vertical-bar {
  background-color: #dddddd;
}

.ui-grid-header-cell:last-child .ui-grid-vertical-bar {
  right: -1px;
  width: 0px;
  background-color: #ffffff;
}

.ui-grid-top-panel-background {
  background: #ffffff;
  background: -webkit-gradient(linear, left bottom, left top, color-stop(0, #ffffff), color-stop(1, #ffffff));
  background: -ms-linear-gradient(bottom, #ffffff, #ffffff);
  background: -moz-linear-gradient(center bottom, #ffffff 0%, #ffffff 100%);
  background: -o-linear-gradient(#ffffff, #ffffff);
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#ffffff', endColorstr='#ffffff', GradientType=0);
}

.ui-grid-header {
  border-bottom: 1px solid #dddddd;
}

.ui-grid-top-panel {
  font-weight: normal;
  background: #ffffff;
  background: -webkit-gradient(linear, left bottom, left top, color-stop(0, #ffffff), color-stop(1, #ffffff));
  background: -ms-linear-gradient(bottom, #ffffff, #ffffff);
  background: -moz-linear-gradient(center bottom, #ffffff 0%, #ffffff 100%);
  background: -o-linear-gradient(#ffffff, #ffffff);
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#ffffff', endColorstr='#ffffff', GradientType=0);
  -webkit-border-top-right-radius: 0px;
  -webkit-border-bottom-right-radius: 0;
  -webkit-border-bottom-left-radius: 0;
  -webkit-border-top-left-radius: 0px;
  -moz-border-radius-topright: 0px;
  -moz-border-radius-bottomright: 0;
  -moz-border-radius-bottomleft: 0;
  -moz-border-radius-topleft: 0px;
  border-top-right-radius: 0px;
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0;
  border-top-left-radius: 0px;
}

.ui-grid-header-cell {

  border-right: 0px solid;
  border-color: #ffffff;

  *[role=button] {
    outline: none;
  }
  *[aria-sort=none] {
    color: $tc-grid-header-color;
  }

}

.ui-grid-column-menu-button {
  position: absolute;
  right: 0px;
  top: 0;
}

input[type="text"].ui-grid-filter-input {
  border: 1px solid #dddddd;
}

input[type="text"].ui-grid-filter-input:hover {
  border: 1px solid #dddddd;
}

select.ui-grid-filter-select {
  border: 0px solid #dddddd;
}

select.ui-grid-filter-select:hover {
  border: 0px solid #dddddd;
}

.ui-grid-row:nth-child(odd) .ui-grid-cell {
  background-color: #ffffff;
}

.ui-grid-row:nth-child(even) .ui-grid-cell {
  background-color: #fcfcfc;
}

.ui-grid-row:hover .ui-grid-cell {
  background-color: #f3f8fd;
  /*color: #fff;
  small.text-gray {
    color: #d0d0d0;
  }*/
}

.ui-grid-row:last-child .ui-grid-cell {
  border-bottom-color: #dddddd;
}

.ui-grid-no-row-overlay {
  background: #ffffff;
  background: -webkit-gradient(linear, left bottom, left top, color-stop(0, #ffffff), color-stop(1, #ffffff));
  background: -ms-linear-gradient(bottom, #ffffff, #ffffff);
  background: -moz-linear-gradient(center bottom, #ffffff 0%, #ffffff 100%);
  background: -o-linear-gradient(#ffffff, #ffffff);
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#ffffff', endColorstr='#ffffff', GradientType=0);
  border: 0px solid #dddddd;
}

.ui-grid-cell {
  border-right: 0px solid;
  border-color: #dddddd;
  border-bottom-style: solid;
  border-bottom-width: 1px !important;
}

.ui-grid-canvas .ui-grid-cell .ui-grid-cell-contents .text-ellipsis a {
    color: $tc-grid-color;
}

.ui-grid-canvas .ui-grid-cell .ui-grid-cell-contents .text-ellipsis a:hover {
    color: #4899ee;
    text-decoration: none;
}

.ui-grid-cell-contents {
  padding: 10px;
}

.logs-table {
  .ui-grid-cell-contents {
    pointer-events: initial;
  }
}

.ui-grid-row .ui-grid-cell.ui-grid-row-header-cell {
  border-bottom: solid 0px #dddddd;
}

.ui-grid-footer-panel-background {
  background: #ffffff;
  background: -webkit-gradient(linear, left bottom, left top, color-stop(0, #ffffff), color-stop(1, #ffffff));
  background: -ms-linear-gradient(bottom, #ffffff, #ffffff);
  background: -moz-linear-gradient(center bottom, #ffffff 0%, #ffffff 100%);
  background: -o-linear-gradient(#ffffff, #ffffff);
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#ffffff', endColorstr='#ffffff', GradientType=0);
}

.ui-grid-footer-panel {
  border-bottom: 1px solid #dddddd;
  border-top: 1px solid #dddddd;
  background: #ffffff;
  background: -webkit-gradient(linear, left bottom, left top, color-stop(0, #ffffff), color-stop(1, #ffffff));
  background: -ms-linear-gradient(bottom, #ffffff, #ffffff);
  background: -moz-linear-gradient(center bottom, #ffffff 0%, #ffffff 100%);
  background: -o-linear-gradient(#ffffff, #ffffff);
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#ffffff', endColorstr='#ffffff', GradientType=0);
}

.ui-grid-footer-cell {
  border-right: 0px solid;
  border-color: #dddddd;
}

.ui-grid-menu-button {
  background: #ffffff;
  border: 0px solid #dddddd;
  right: 16px;
  top: 7px;
  height: auto;
}

.ui-grid-menu-button .ui-grid-menu .ui-grid-menu-mid {
  border: 1px solid #dddddd;
}

.ui-grid-menu .ui-grid-menu-inner {
  background: #ffffff;
  border: 0px solid #dddddd;
}

.ui-grid-menu .ui-grid-menu-inner ul li button.ui-grid-menu-item-active {
  background-color: #eeeeee;
}

.ui-grid-menu .ui-grid-menu-inner ul li:not(:last-child) > button {
  border-bottom: 0px solid #dddddd;
}

.ui-grid[dir=rtl] .ui-grid-cell:last-child,
.ui-grid[dir=rtl] .ui-grid-header-cell:last-child {
  border-right: 0px solid #dddddd;
  border-left: 0;
}

.ui-grid[dir=rtl] .ui-grid-menu-button {

  background: #ffffff;
  border: 0px solid #dddddd;

}

/*---------------------------------------------------
    LESS Elements 0.9
  ---------------------------------------------------
    A set of useful LESS mixins
    More info at: http://lesselements.com
  ---------------------------------------------------*/
.ui-grid {
  color: $tc-grid-color;
}

.ui-grid-header {
  border-bottom-width: 1px;
}

.ui-grid-menu .ui-grid-menu-inner ul li:not(:last-child) > button {
  border: none;
}

.ui-grid-menu .ui-grid-menu-inner ul li button.ui-grid-menu-item-active {
  -webkit-box-shadow: none;
  -moz-box-shadow: none;
  box-shadow: none;
}

.ui-grid-menu .ui-grid-menu-inner ul li button:hover,
.ui-grid-menu .ui-grid-menu-inner ul li button:focus {
  -webkit-box-shadow: none;
  -moz-box-shadow: none;
  box-shadow: none;
  background-color: #ddd;
}

.tc-cell-editable {
  .ui-grid-cell-contents {
    text-decoration: underline;
    text-decoration-style: dashed;
    text-underline-position: under;
  }
}

.tc-cell-small {
  .ui-grid-cell-contents {
    font-size: 12px;
  }
}


// draggable row style
.ui-grid-draggable-row-over {
  position: relative;
  box-sizing: border-box;
}
.ui-grid-draggable-row-over:before {
  content: "";
  display: block;
  position: absolute;
  left: 0;
  width: 100%;
  border: 1px solid #f00;
  box-sizing: border-box;
}
.ui-grid-draggable-row-over--above:before {
  top: -1px;
  box-sizing: border-box;
}
.ui-grid-draggable-row-over--below:before {
  bottom: -1px;
  box-sizing: border-box;
}


.ui-grid.grid-wrap-headers {
  .ui-grid-header-cell-label {
    white-space: pre-wrap;
  }
}

.ui-grid-row.ui-grid-row-selected {

  // &:nth-child(even)>[ui-grid-row]>.ui-grid-cell { background-color: transparent !important; }
  // &:nth-child(odd)>[ui-grid-row]>.ui-grid-cell { background-color: transparent !important; }

  &:hover {
    &:nth-child(even)>[ui-grid-row]>.ui-grid-cell { background-color: #f3f8fd !important; }
    &:nth-child(odd)>[ui-grid-row]>.ui-grid-cell { background-color: #f3f8fd !important; }
  }
}

.tc-deal-id {
  padding-right: 0;

  > a { display: block; }
  .fa { opacity: .6; }
}

.tc-company-name {
  .name {display: block;}
  .fa { opacity: .6;}
}
