html {
  --mdc-dialog-content-padding-ver: 20px;
  --mdc-dialog-content-padding-hor: 24px;

  .mdc-dialog__title+.mdc-dialog__content,
  .mdc-dialog__header+.mdc-dialog__content {
    padding-top: 20px; // post-ng15 fix: restore mat-dialog-content to default 20px
  }

  body.md-dialog-is-showing { // ng1 fixes for md-date-range-picker calling $mdDialog.show
    position: fixed;
  }

  .mat-mdc-dialog-container {
    padding: 0;
  }

  .mat-mdc-dialog-title {
    display: flex;
    margin: 0;
    height: 80px;
    padding: 20px 30px;
    align-items: center;
  }

  .mat-mdc-dialog-content {
    margin: 0;
    border-top: 1px solid #d6d6d6;
    border-bottom: 1px solid #d6d6d6;
    background-color: #fafafa;
    padding: 20px 30px 10px;
  }

  // NOTE: override material style
  .mat-mdc-dialog-container .mat-mdc-dialog-title+.mat-mdc-dialog-content {
    padding-top: 20px;
  }

  .mat-mdc-dialog-actions {
    height: 80px;
    margin: 0;
    padding: 0 30px;
  }
}

.modal-full {
  width: calc(100vw - 40px);
}

.modal-xlg {
  @media (min-width: 992px) { width: 900px; }
  @media (min-width: 1242px) { width: 1180px; }
}

.modal-lg {
  @media (min-width: 992px) { width: 900px; }
  @media (min-width: 768px) { width: 600px; }
}

.modal-md {
  @media (min-width: 992px) { width: 600px; }
  @media (min-width: 768px) { width: 600px; }
}

.mat-mdc-dialog-title {
  i.fa,
  .mat-icon {
    font-size: 24px;
    margin-right: 10px;
  }

  h1,h2,h3,h4,h5,h6 {
    margin-bottom: 0;
  }

  > *:not(:first-child) {
    margin-left: 15px;
  }

  .mat-mdc-form-field {
    font-size: 14px; // don't inherit font-size from mat-dialog-title
  }
}


@keyframes slide {
  100% { transform: translateX(0); }
}

.tc-dialog {
  .mat-mdc-dialog-content {
    max-height: calc(100vh - 160px - 30px);
  }
}

.mdc-dialog .mdc-dialog__content {
  padding: var(--mdc-dialog-content-padding-ver) var(--mdc-dialog-content-padding-hor) var(--mdc-dialog-content-padding-ver) var(--mdc-dialog-content-padding-hor);
  // https://stackoverflow.com/a/76904309/265839
  color: inherit !important;
  line-height: inherit !important;
  font-size: inherit !important;
  font-weight: inherit !important;
  letter-spacing: inherit !important;
}

.tc-overlay {
  position: fixed !important;
  bottom: 0;
  top: 0;
  width: 100vw;
  animation: slide .3s forwards;
  // animation-delay: .3s;

  &.right {
    right: 0;
    transform: translateX(100vw);
  }

  &.left {
    left: 0;
    transform: translateX(-100vw);
  }

  .mat-mdc-dialog-content {
    max-height: initial;
    height: calc(100vh - 80px);
  }

  .mat-mdc-dialog-container > * {
    display: flex;
    flex-direction: column;
    max-height: 100%;

    > .modal-header { flex: 0; }
    // > .modal-body { overflow: scroll; }
  }
}

.confirm-modal { // old confirm modal
  z-index: 10002 !important;
  & + .modal-backdrop {
    z-index: 10001 !important;
  }
}

// .ng1-to-foreground {
// UNUSED, but available
// }

.ng1-to-background {
  > [uib-modal-window],
  > [uib-modal-backdrop] {
    z-index: 900 !important;
  }
}
