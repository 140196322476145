tc-document-set-form {
  /**
   * The dndDraggingSource class will be applied to
   * the source element of a drag operation. It makes
   * sense to hide it to give the user the feeling
   * that he's actually moving it.
   */
  .dnd-list-wrapper {
    li {
      padding-top: 5px;
      padding-bottom: 5px;
    }

    .handle {
      padding-right: 10px;
    }

    &.tc-set-docs {
      .dndDraggingSource {
        display: none;
      }

      .dndPlaceholder {
        min-height: 58px; // measured manually in chrome :/
      }
    }


    .tc-remove-doc { // remove button
      margin: -2px 0;
      padding: 10px 8px 0;
      background: radial-gradient(20px, white, 15px, transparent);
    }

    .fa-minus-circle {
      color: #f00;
    }

    .btn-success {
      margin-left: 10px;
    }

    .tc-disabled-drag {
      opacity: .5;
      pointer-events: none;
    }
  }
}
