@import '../../variables';

%tc-date-icon {
  padding-right: 35px;
  background: $tc-white url('/assets/images/icons/date.png') no-repeat right 15px center;
}

.date-icon,
// override md-input-container styles
md-input-container .md-input.date-icon {
  @extend %tc-date-icon;
}
