a[mat-menu-item] {
  &, &:hover, &:focus {
    // color: inherit;
    text-decoration: none;
  }
}

html {
  .mat-mdc-menu-item {
    --mat-menu-item-label-text-size: 14px;
    --mat-menu-item-label-text-tracking: 0;
    --mat-menu-item-label-text-weight: 400;
    --mat-menu-item-label-text-line-height: 34px;
    height: 34px;
    min-height: 34px;
  }

  .mat-mdc-menu-content,
  .mat-mdc-menu-content .mat-mdc-menu-item .mat-mdc-menu-item-text {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
}
