.mat-mdc-table tbody,
.mat-mdc-table tfoot {
  --mat-table-row-item-label-text-color: #555;
  --mat-table-row-item-label-text-size: 13px;
}

html {
  .mat-mdc-table {
    table-layout: fixed;
    width: 100%;
  }

  tr,
  mat-header-row {
    &.mat-mdc-header-row.tc-filters-row {
      height: auto; // override unknown td.mat-header-row
    }
  }
  tr,
  mat-footer-row {
    &.mat-mdc-footer-row:not(.tc-show-footer) {
      display: none;
    }
  }

  th,
  mat-header-cell {
    &.mat-mdc-header-cell {
      font-weight: initial;
      color: #919191;
      font-size: 13px;
      padding: 10px 0 5px 5px;

      &.mat-header-cell-filter {
        padding: 4px 2px;
      }

      .mat-sort-header-content {
        display: block;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
      }
    }
  }

  td,
  mat-cell {
    &.mat-mdc-cell {
      vertical-align: top;
      padding: 10px 5px 0;
      height: 60px;
    }

    &.mat-mdc-footer-cell {
      font-weight: 700;
      border-bottom: none;
      padding-left: 5px;
      padding-right: 5px;
    }
  }


  // NOTE: i have no idea why right after page load default sorted header is not visible
  .mat-sort-header-sorted .mat-sort-header-arrow {
    opacity: 1 !important;
  }

  // give column headers more space
  .mat-sort-header-arrow {
    position: absolute;
    right: 0;
    background: rgba(255,255,255,.5);
  }


  .mat-header-cell-filter {
    padding: 0;

    .mat-mdc-form-field {
      max-width: 100%;
    }

    // we customize gaps between fields using grid-gap
    // this is possible because we don't use inline validation messages (yet?)
    .mat-mdc-form-field-subscript-wrapper::before { content: initial }

    .mat-date-range-input-separator {
      margin: 0;
    }
  }

  .tc-cell-editable {
    text-decoration: underline;
    text-decoration-style: dashed;
    text-underline-position: under;
  }


  // override default mat-table left padding
  th,
  mat-header-cell {
    &.mat-mdc-header-cell:first-of-type {
      padding-left: 30px;
    }
  }
  td,
  mat-cell {
    &.mat-mdc-cell:first-of-type,
    &.mat-mdc-footer-cell:first-of-type {
      padding-left: 30px;
    }
  }

  // override default mat-table right padding
  th,
  mat-header-cell {
    &.mat-mdc-header-cell:last-of-type {
      padding-right: 30px;
    }
  }
  td,
  mat-cell {
    &.mat-mdc-cell:last-of-type,
    &.mat-mdc-footer-cell:last-of-type {
      padding-right: 30px;
    }
  }

  // custom even/odd/hover colors
  tr,
  mat-row {
    > .mat-mdc-cell { background-color: #fff; }
    &:nth-child(2n) > .mat-mdc-cell { background-color: #fcfcfc; }
    &:hover:not(.tc-no-hover) {
      > td,
      > mat-cell {
        &.mat-mdc-cell { background-color: #d5eaee; }
      }
    }
  }

  tr,
  mat-row {
    &.clickable .mat-mdc-cell {
      cursor: pointer;
    }
  }

  .mdc-data-table__row:last-child .mat-mdc-cell {
    border-bottom-color: var(--mat-table-row-item-outline-color, rgba(0, 0, 0, 0.12));
    border-bottom-width: var(--mat-table-row-item-outline-width, 1px);
    border-bottom-style: solid;
  }
}
