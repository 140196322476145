%required-star {
  &::after {
    display: inline-block;
    padding-left: 5px;
    color: $tc-red;
    content: '*';
  }
}

%always-show-required-star {
  &::after {
    display: inline-block;
    padding-left: 5px;
    color: $tc-red;
    content: '*';
    position: absolute;
    right: 0px;
  }
}

.mat-mdc-form-field-required-marker {
  color: $tc-red;
}
