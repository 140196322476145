tc-message-view-form {
  .title {
    font-weight: bold;
  }

  .content {
    word-break: break-all;
  }

  .message-content-wrap {
    background-color: #ddd;
    .message-content {
      min-height: 250px;
      max-height: 300px;
      word-break: break-word;
      width: 100%;
      overflow-y: scroll;

      pre {
        white-space: pre-wrap;
      }
    }
  }
}
