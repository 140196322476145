%tc-page-with-sticky-header {
  .title-holder {
    margin: -30px -30px 0;
    padding: 20px 15px 15px;
    transition: background-color .3s, box-shadow .5s;

    &.tc-is-sticky {
      // padding: 10px 30px 10px 15px;
      // @media (max-width: 768px) { padding: 10px 15px 10px 15px; }
      background: #fff;
      box-shadow: 0 2px 3px rgba(0, 0, 0, 0.25);
    }
  }

  // fix conflict with tc-sticky, which also has z-index:2
  .search-holder .input-group {
    .form-control,
    .btn { z-index: 1; }
  }
}


%tc-button {
  // NOTE: copy of .title-holder .btn-holder .btn
  min-width: 120px;
  padding: 8px 15px;
  font-size: 13px;
  text-transform: uppercase;
}

%tc-fix-ui-grid-header {
  .ui-grid-header-viewport,
  .ui-grid-render-container-left .ui-grid-header-viewport,
  .ui-grid-render-container-left .ui-grid-header-canvas,
  .ui-grid-render-container-body .ui-grid-header-canvas,
  .grid .ui-grid-header-cell {
    height: 40px !important;
  }

}
