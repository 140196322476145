tc-shipment-tracking {
  display: block;

  .dropdown-menu-cell .ui-grid-cell-contents {
    padding: 0;
  }

  .tc-map-overlay,
  ng-map {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    height: 100% !important;
  }

  .tc-sidenav {
    position: fixed;
    width: 30%;
    padding: 15px;
  }

  input {
    border: 0 !important;
    box-shadow: 0px 1px 3px 0px rgba(0, 0, 0, 0.2),
                0px 1px 1px 0px rgba(0, 0, 0, 0.14),
                0px 2px 1px -1px rgba(0, 0, 0, 0.12) !important;
  }

  md-card {
    &, &:focus, &:active { outline: none }
  }

  md-card-title {
    padding: 16px 16px 8px 16px;
    font-size: 15px;
    font-weight: 500;
  }

  md-card-content {
    padding: 0 8px 8px 8px;
    .table {
      font-size: 12px;
      margin-bottom: 0;
    }
  }

  .tc-current-event {
    font-weight: 500;
  }

  .tc-selected-point {
    transform: scale(1.04);
    transition: transform 300ms;
  }
}
