@import '../variables';
@import './shared/date-icon';

// make picker visible, when opened from inside a modal dialog
.md-open-menu-container.md-active {
  z-index: 100000;
}

.md-custom-menu-content {
  > .ng-binding {
    margin-top: 12px;
  }
}

.md-select-value,
.md-custom-menu-content {
  > .ng-binding {
    font-size: $tc-input-font-size;
  }
}

md-date-range {
  padding: 0;
  border: 0;
  display: block;
  height: 50px;

  &[disabled] {

    *,
    *:active,
    *:focus {
      outline: none;
      cursor: no-drop;
    }

    .md-select-value {
      background-color: #fafafa;
      opacity: .7;
    }
  }

  .ng-submitted &.ng-invalid,
  &.ng-invalid.ng-dirty,
  &.ng-invalid.ng-touched {
    @include shake();
    .md-select-value {
      border-color: $tc-red;
    }
  }

  &.ng-empty {
    .md-select-value {
      color: $tc-placeholder-color;
    }

    &[required] .md-select-value > .ng-binding {
      @extend %required-star;
    }
  }

  .md-select-value {
    @extend %tc-date-icon;

    padding: 0 30px 0 12px;
    border: $tc-input-border;
    border-radius: $tc-input-border-radius;
    line-height: 48px;

    .md-select-icon {
      display: none;
    }

    .md-input-has-value &,
    .setField & {
      padding-top: 20px;
      line-height: 28px;
    }
  }
}

.tc-daterange {
  position: relative;

  .clear-btn {
    display: none;
  }

  .fieldTitle {
    pointer-events: none; // forward clicks to md-date-range
  }

  &.setField,
  &.md-input-has-value {
    .clear-btn {
      display: inline-block;
      position: absolute;
      top: 27px;
      right: 30px;
    }

    .fieldTitle {
      left: 12px;
      width: calc(100% - 12px);
      text-overflow: ellipsis;
      white-space: nowrap;
      overflow: hidden;
    }
  }
}
