@use '@angular/material' as mat;
@use "sass:map";
@use '@ng-matero/extensions' as mtx;

@import './nav-menu-theme';

// Fonts
@import 'https://fonts.googleapis.com/css?family=Material+Icons';
@import url('https://fonts.googleapis.com/css?family=Roboto:300,400,500');

$fontConfig: (
  display-4: mat.m2-define-typography-level(112px, 112px, 300, 'Roboto', -0.0134em),
  display-3: mat.m2-define-typography-level(56px, 56px, 400, 'Roboto', -0.0089em),
  display-2: mat.m2-define-typography-level(45px, 48px, 400, 'Roboto', 0.0000em),
  display-1: mat.m2-define-typography-level(34px, 40px, 400, 'Roboto', 0.0074em),
  headline: mat.m2-define-typography-level(24px, 32px, 400, 'Roboto', 0.0000em),
  title: mat.m2-define-typography-level(20px, 32px, 400, 'Roboto', 0.0075em),
  subheading-2: mat.m2-define-typography-level(16px, 28px, 400, 'Roboto', 0.0094em),
  subheading-1: mat.m2-define-typography-level(15px, 24px, 500, 'Roboto', 0.0067em),
  body-2: mat.m2-define-typography-level(14px, 24px, 500, 'Roboto', 0.0179em),
  body-1: mat.m2-define-typography-level(14px, 20px, 400, 'Roboto', 0.0179em),
  button: mat.m2-define-typography-level(14px, 14px, 500, 'Roboto', 0),
  caption: mat.m2-define-typography-level(12px, 20px, 400, 'Roboto', 0.0333em),
  input: mat.m2-define-typography-level(inherit, 1.125, 400, 'Roboto'/* , 1.5px */)
);

// Foreground Elements

// Light Theme Text
$dark-text: #000000;
$dark-primary-text: rgba($dark-text, 0.87);
$dark-accent-text: rgba(rgba(black, 0.87), 0.54);
$dark-disabled-text: rgba(rgba(black, 0.87), 0.38);
$dark-dividers: rgba(rgba(black, 0.87), 0.12);
$dark-focused: rgba(rgba(black, 0.87), 0.12);

$light-theme-foreground-palette: (
  base:              black,
  divider:           rgba(black, 0.12),
  dividers:          rgba(black, 0.12),
  disabled:          rgba(black, 0.38),
  disabled-button:   rgba($dark-text, 0.26),
  disabled-text:     rgba(black, 0.38),
  elevation:         black,
  secondary-text:    $dark-accent-text,
  hint-text:         rgba(black, 0.38),
  accent-text:       $dark-accent-text,
  icon:              $dark-accent-text,
  icons:             $dark-accent-text,
  text:              rgba(black, 0.87),
  slider-min:        rgba(black, 0.87),
  slider-off:        rgba($dark-text, 0.26),
  slider-off-active: rgba(black, 0.38),
);

// Dark Theme text
$light-text: #ffffff;
$light-primary-text: $light-text;
$light-accent-text: rgba(white, 0.7);
$light-disabled-text: rgba(white, 0.5);
$light-dividers: rgba(white, 0.12);
$light-focused: rgba(white, 0.12);

$dark-theme-foreground-palette: (
  base:              $light-text,
  divider:           rgba(white, 0.12),
  dividers:          rgba(white, 0.12),
  disabled:          rgba(white, 0.5),
  disabled-button:   rgba($light-text, 0.3),
  disabled-text:     rgba(white, 0.5),
  elevation:         black,
  hint-text:         rgba(white, 0.5),
  secondary-text:    $light-accent-text,
  accent-text:       $light-accent-text,
  icon:              $light-text,
  icons:             $light-text,
  text:              $light-text,
  slider-min:        $light-text,
  slider-off:        rgba($light-text, 0.3),
  slider-off-active: rgba($light-text, 0.3),
);

// Background config
// Light bg
$light-background:    #fafafa;
$light-bg-darker-5:   darken($light-background, 5%);
$light-bg-darker-10:  darken($light-background, 10%);
$light-bg-darker-20:  darken($light-background, 20%);
$light-bg-darker-30:  darken($light-background, 30%);
$light-bg-lighter-5:  lighten($light-background, 5%);
$dark-bg-tooltip:     lighten(#2c2c2c, 20%);
$dark-bg-alpha-4:     rgba(#2c2c2c, 0.04);
$dark-bg-alpha-12:    rgba(#2c2c2c, 0.12);

$light-theme-background-palette: (
  background:               $light-background,
  status-bar:               $light-bg-darker-20,
  app-bar:                  $light-bg-darker-5,
  hover:                    $dark-bg-alpha-4,
  card:                     $light-bg-lighter-5,
  dialog:                   $light-bg-lighter-5,
  tooltip:                  $dark-bg-tooltip,
  disabled-button:          $dark-bg-alpha-12,
  raised-button:            $light-bg-lighter-5,
  focused-button:           rgba(black, 0.12),
  selected-button:          $light-bg-darker-20,
  selected-disabled-button: $light-bg-darker-30,
  disabled-button-toggle:   $light-bg-darker-10,
  unselected-chip:          $light-bg-darker-10,
  disabled-list-option:     $light-bg-darker-10,
);

// Dark bg
$dark-background:     #2c2c2c;
$dark-bg-lighter-5:   lighten($dark-background, 5%);
$dark-bg-lighter-10:  lighten($dark-background, 10%);
$dark-bg-lighter-20:  lighten($dark-background, 20%);
$dark-bg-lighter-30:  lighten($dark-background, 30%);
$light-bg-alpha-4:    rgba(#fafafa, 0.04);
$light-bg-alpha-12:   rgba(#fafafa, 0.12);

// Background palette for dark themes.
$dark-theme-background-palette: (
  background:               $dark-background,
  status-bar:               $dark-bg-lighter-20,
  app-bar:                  $dark-bg-lighter-5,
  hover:                    $light-bg-alpha-4,
  card:                     $dark-bg-lighter-5,
  dialog:                   $dark-bg-lighter-5,
  tooltip:                  $dark-bg-lighter-20,
  disabled-button:          $light-bg-alpha-12,
  raised-button:            $dark-bg-lighter-5,
  focused-button:           rgba(white, 0.12),
  selected-button:          $dark-bg-lighter-20,
  selected-disabled-button: $dark-bg-lighter-30,
  disabled-button-toggle:   $dark-bg-lighter-10,
  unselected-chip:          $dark-bg-lighter-20,
  disabled-list-option:     $dark-bg-lighter-10,
);

$mat-tooltip-font-size: 12px;
$mat-tooltip-vertical-padding: 2px;

// Compute font config
@include mat.all-component-typographies($fontConfig);
@include mat.core();

// Theme Config
body {
  --primary-color: #4899ee;
  --primary-lighter-color: #c8e0fa;
  --primary-darker-color: #307ce7;
  --text-primary-color: #{rgba(black, 0.87)};
  --text-primary-lighter-color: #{rgba(black, 0.87)};
  --text-primary-darker-color: #{white};
}
$theme-primary: mat.m2-define-palette((
  main: #4899ee,
  lighter: #c8e0fa,
  darker: #307ce7,
  200: #4899ee, // For slide toggle,
  contrast : (
    200: white,
    main: white,
    lighter: rgba(black, 0.87),
    darker: white,
  )
), main, lighter, darker);

body {
  --accent-color: #51a351;
  --accent-lighter-color: #cbe3cb;
  --accent-darker-color: #378837;
  --text-accent-color: #{rgba(black, 0.87)};
  --text-accent-lighter-color: #{rgba(black, 0.87)};
  --text-accent-darker-color: #{white};
}
$theme-accent: mat.m2-define-palette((
  main: #51a351,
  lighter: #cbe3cb,
  darker: #378837,
  200: #51a351, // For slide toggle,
  contrast : (
    main: rgba(black, 0.87),
    lighter: rgba(black, 0.87),
    darker: white,
  )
), main, lighter, darker);

body {
  --warn-color: #ff2d3a;
  --warn-lighter-color: #ffc0c4;
  --warn-darker-color: #ff1c25;
  --text-warn-color: #{white};
  --text-warn-lighter-color: #{rgba(black, 0.87)};
  --text-warn-darker-color: #{white};
}
$theme-warn: mat.m2-define-palette((
  main: #ff2d3a,
  lighter: #ffc0c4,
  darker: #ff1c25,
  200: #ff2d3a, // For slide toggle,
  contrast : (
    main: white,
    lighter: rgba(black, 0.87),
    darker: white,
  )
), main, lighter, darker);;

$tradecafe-light-theme: mat.m2-define-light-theme((
  color: (
    primary: $theme-primary,
    accent: $theme-accent,
    warn: $theme-warn,
  ),
  typography: $fontConfig,
  density: 0,
));

// Theme Init
@include mat.all-component-themes($tradecafe-light-theme);
@include mtx.datetimepicker-theme($tradecafe-light-theme);

.theme-nav-menu {
  @include nav-menu-theme($tradecafe-light-theme);
}

// Specific component overrides, pieces that are not in line with the general theming

.mat-mdc-standard-chip {
  padding: .5em .85em;
  min-height: 2.5em;
}

// .material-icons {
//   font-size: 24px;
//   font-family: 'Material Icons', 'Material Icons';

//   .mat-badge-content {
//     font-family: 'Roboto';
//   }
// }

html {
  // custom mat-menu dimensions
  $mat-menu-item-height: 34px;
  $mat-menu-vertical-padding: 4px;
  .mat-mdc-menu-content:not(:empty) {
    padding-top: $mat-menu-vertical-padding;
    padding-bottom: $mat-menu-vertical-padding;
  }
  .mat-mdc-menu-item {
    outline: 0 !important;
    height: $mat-menu-item-height;
    line-height: $mat-menu-item-height;
  }

  .mat-mdc-menu-panel {
    min-height: initial;
  }

  .cdk-overlay-transparent-backdrop, // assuming it is used only by Angular popup elements like mat-select or date pickers ...
  .cdk-overlay-connected-position-bounding-box, // ... these elements must always be infront of everything else.
  .cdk-global-overlay-wrapper:not(.tc-modal-overlay), // assumption "wrapper is used only by Angular snackbar component" is incorrect.
  .cdk-overlay-container { // cdk-overlay-container must be in front of any ng1 modals added directly to the `document.body`
    z-index: 10000;
  }

  .cdk-overlay-container {
    > .modal,
    > .modal-backdrop {
      pointer-events: initial;
    }
  }
}
