@import 'src/assets/styles/variables';
@import 'src/assets/styles/components/shared/required-star';

.md-select-menu-container {
  z-index: 100050; // bootstrap lowest modal z-index is 1050 // WA-4203
}

md-backdrop.md-select-backdrop {
  z-index: 30049;
}

md-select-menu md-content md-option[selected] {
  &,
  &:not([disabled]):hover,
  &:not([disabled]):focus {
    color: white;
    background: #337ab7;
  }

  .text-gray {
    color: #ddd;
  }

  &.md-checkbox-enabled[selected] .md-icon {
    background: white;
  }
}

// style md-select, placed inside .tc-md-block (see md-input-container for more details)
md-input-container.tc-md-block {

  md-select.form-control {
    padding: 18px 10px 6px 12px;

    &[disabled] {
      background: $tc-input-readonly-background;
      cursor: no-drop;

      .md-select-value {
        background-image: none; // override dashed underline
        opacity: .7;
      }
    }

    .md-select-value {
      padding: 2px 0 0;
      border-bottom: 0 !important; // override material underline
    }

    .md-select-icon {
      margin: 0;
      width: auto;
    }
  }

  &:not(md-input-focused) md-select.form-control.ng-empty {
    padding: 12px 10px 6px 12px;
  }

  .ng-empty &.md-input-focused label:not(.md-no-float) {
    transform: translate3d(10px, 40px, 0) scale(1);
  }

  &.md-input-focused:not([md-no-float]) md-select {
    .md-select-placeholder span:first-child {
      transform: translateY(-18px) translateX(-2px) scale(.75);
    }

    &.ng-not-empty .md-select-placeholder span:first-child {
      transform: none;
      color: black;
    }
  }

  md-option {
    padding: 0 10px;
  }


  &.md-input-has-value {
    label:not(.md-no-float) {
      transform: translate3d(10px, 30px, 0) scale(.85);
      opacity: 1;
      top: initial;
      // color: $tc-label-font-color;
      // transform
    }
  }

  .clear-btn {
    display: inline-block;
    position: absolute;
    right: 12px;
    top: 6px;
    color: #9e9e9e;
  }
}

// tc-select component styles
.always-show-asterisk > tc-select {
  &[required] > md-input-container > .md-placeholder {
    @extend %always-show-required-star;
  }
}

tc-select {
  &[required] > md-input-container > label {
    @extend %required-star;
  }

  &.ng-not-empty {
    .md-select-value.md-select-placeholder {
      color: $tc-black;
    }

    > :not(.md-input-has-value) > label:not(.md-no-float):not(.md-container-ignore) {
      height: 18px;
      transform: translate3d(10px, 6px, 0) scale(.8);
      opacity: 1;

      &:not(.md-placeholder) {
        transform: translate3d(10px, 30px, 0) scale(.8);
      }
    }
  }

  &.ng-empty > md-input-container > .md-placeholder {
    top: auto;
    opacity: 1;
  }

  > md-input-container.md-input-focused label:not(.md-no-float) {
    transform: translate3d(10px, 40px, 0) scale(1);
  }
}

// tc select menu added to the body
.tc-select-container {
  md-select-menu { min-width: 200px; }

  md-content._md {
    padding-top: 70px; // give header some space
  }

  .tc-select-header {
    position: absolute;
    top: 0;
    width: 100%;
    border-bottom: 1px solid rgba(0, 0, 0, 0.12);
    background: white;
    cursor: pointer;
    padding: 8px;
    z-index: 1;

    md-input-container {
      margin-top: 0;
    }
    .select-all-button {
      margin: 0 0 0 2px;
    }
  }

  $tc-select-menu-height: 410px;

  // We make header float above the menu,
  // 1. so hide placeholder
  .md-container-ignore {
    display: none;
  }
  // 2. make menu visible
  md-select-menu,
  md-content._md {
    overflow-y: visible;
  }
  // 3. limit menu height
  md-select-menu,
  md-content._md {
    max-height: $tc-select-menu-height;
  }

  md-virtual-repeat-container { // for this one we set height in JS
    max-height: $tc-select-menu-height - 70px;
  }
}
