@use "sass:math";
@import './variables';
@import './mixins';

.modal.left,
.modal.right {

  .modal-dialog {
    position: fixed;
    width: 90%;
    height: 100%;
    margin: auto;
    transform: translate3d(0%, 0, 0);
  }

  .modal-content {
    height: 100%;
    border: 0;
    border-radius: 0;
    box-shadow: none;
    overflow-y: auto;
  }

  .modal-body {
    padding: 15px 15px 80px;
  }

  &.fade .modal-dialog {
    transition: opacity .3s linear, transform .3s ease-out;
  }

  // Left
  &.left.fade .modal-dialog {
    left: 0;
    transform: translateX(-320px);
  }

  // Right
  &.right.fade .modal-dialog {
    right: 0;
    transform: translateX(320px);
  }

  &.fade.in .modal-dialog {
    transform: translateX(0);
  }
}

// body {
//   transition: transform .3s;
//   &.tc-overlay-open {
//     transform: translateX(-320px);
//   }
// }

.tc-modal-overlay .modal-content {
  > .md-title,
  > * > .md-title {
    padding: 0 30px 15px;
    line-height: 30px;
  }

  .btn.btn-link,
  .btn.btn-primary,
  .btn.btn-warning {
    @extend %tc-button;
    // margin-left: 30px;
  }

  .btn.btn-link {
    color: #202020;

    &:focus,
    &:hover {
      text-decoration: none;
    }
  }

  .btn.btn-primary,
  .btn.btn-warning {
    box-shadow: 2px 2px 6px 1px #ddd;
  }
}

.md-button.tc-close-overlay {
  position: absolute;
  top: 10px;
  right: 100%;
  background: $tc-white;
  font-size: 24px;
  box-shadow: 3px 3px 10px rgba(0, 0, 0, .4);

  &:hover {
    background: #4899ee !important;
    outline: none;
    color: $tc-white;

    .fa {
      color: $tc-white;

      &,
      &:active,
      &:focus { outline: none; }
    }
  }
}


@media print{
  .tc-overlay-open.modal-open tc-app {
    display: none;
  }

  .modal.left,
  .modal.right {
    position: relative;
    height: auto;

    .modal-dialog {
      position: relative;
      width: 100%;
      height: auto;

      @for $i from 1 through 12 {
        .col-sm-#{$i} {
          width: #{percentage(math.div(round($i * 8.33), 100))};
          float: left;
        }

        .col-sm-offset-#{$i} {
          margin-left: #{percentage(math.div(round($i * 8.33), 100))};
        }
      }

      .wht-bg-container {
        padding: 0;
        border: 0;
      }

      .invoice-item-list .form-control {
        height: 1.2em;
        padding: 0;
        border: 0;
      }
    }
  }
}
