// scss-lint:disable SelectorDepth
// scss-lint:disable NestingDepth

// CUT from page.cost-sheets.table
.title-holder {
  margin-bottom: 30px;
  white-space: nowrap;
  vertical-align: top;

  .btn-holder {
    text-align: right;
    white-space: normal;

    .divider { // tc-btn-divider
      margin-left: 10px;

      &::before {
        @extend %title-divider;
      }
    }


    .btn {
      min-width: 120px;
      margin-left: 30px;
      padding: 8px 15px;
      font-size: 13px;
      text-transform: uppercase;

      @media (max-width: 768px) {
        margin-right: 0;
        margin-left: 15px;
      }

      // 2 primary buttons doesn't fit into one row on iphone4
      @media (max-width: 480px) {
        margin-left: 0;
      }
    }

    .btn .fa {
      margin-right: 8px;
    }

    .btn.btn-link {
      margin-left: 0px;
      padding: 8px 10px;
      min-width: 0; // override bootstrap
    }

    .btn-link {
      &,
      &:focus {
        color: #505050;
        font-weight: 500;
        text-decoration: none;
        outline: none;
        box-shadow: none;
      }

      &:hover {
        color: #4899ee;
        text-decoration: none;
      }
    }

    .btn-info {
      &,
      &:focus {
        border-color: #58c264;
        outline: none;
        background: #58c264;
        box-shadow: 2px 2px 6px 1px #ddd;
      }

      &:hover {
        background: #4eb259;
        border-color: #58c264;
      }
    }

    .btn-action {
      &,
      &:focus {
        border-color: #eea236;
        outline: none;
        background: #f0ad4e;
        color: #fff;
        box-shadow: 2px 2px 6px 1px #ddd;
      }

      &:hover {
        background: #eea236;
        border-color: #eea236;
      }
    }
  }

  .btn-holder-right {
    text-align: right;
    white-space: normal;

    ul {
      margin: 0;
      padding: 0;
    }

    ul li {
      margin-left: 15px;
    }

    ul li.divider:before {
      @extend %title-divider;
    }

    ul li a {
      font-size: 15px;
      color: #202020;
      text-transform: uppercase;
      font-weight: 500;

      &:focus {
        text-decoration: none;
        outline: none;
      }

      &:hover {
        color: #4899ee;
        text-decoration: none;
      }

      &.btn {
        padding: 10px 15px;
        border-color: transparent;
        font-weight: 400;
      }

      &.btn-danger {
        color: #fff;
        background: #ea3939;

        &:hover {
          color: #fff;
          background: #f71111;
        }
      }

      &.btn-info {
        color: #fff;
        background: #3998ea;

        &:hover {
          color: #fff;
          background: #178bee;
        }
      }

      img {
        margin-right: 5px;
        position: relative;
        top: -2px;
        max-height: 20px;

        &.hover-in { display: none; }
      }

      &:hover img {
        &.hover-in  { display: inline-block; }
        &.hover-out { display: none; }
      }
    }

    @media (min-width: 1390px) {
      ul li a.btn {
        padding: 10px 35px;
      }
    }

    @media (max-width: 640px) {
      ul li.divider::before {
        content: normal;
      }
    }
  }
}


.page-new-cost-sheet .title-holder {
  .title-md {
    @media (max-width: 1290px)                        { font-size: 16px; }
    @media (max-width: 1120px) and (min-width: 768px) { font-size: 14px; }
  }

  .btn-holder-right {
    ul li a {
      @media (max-width: 1290px)                        { font-size: 14px; }
      @media (max-width: 1120px) and (min-width: 768px) { font-size: 12px; }
      @media (max-width: 768px)                         { font-size: 10px; }
    }

    ul li {
      @media (max-width: 1120px) and (min-width: 768px) { margin-left: 6px; }
      @media (max-width: 768px)                         { margin-left: 6px; }
      @media (max-width: 440px)                         { margin: 0 5px 10px 0; }
    }

    ul li a img {
      @media (max-width: 1120px) and (min-width: 768px) {
        margin-right: 4px;
        max-height: inherit;
        max-width: 14px;
      }
      @media (max-width: 768px) {
        margin-right: 4px;
        max-height: inherit;
        max-width: 14px;
      }
    }

    ul li a.btn {
      @media (max-width: 1120px) and (min-width: 768px) {
        padding: 8px 10px;
        font-size: 11px;
      }

      @media (max-width: 768px) {
        padding: 8px 10px;
        font-size: 11px;
      }
    }

    @media (max-width: 768px) {
      ul {
        text-align: left;
      }
    }
  }
}

%title-divider {
  content: " ";
  height: 100%;
  width: 1px;
  position: absolute;
  background: #d4d4d5;
  top: 0;
}

// CUT: from page.shipping.scss
.page-new-cost-sheet.shipping-page .title-holder {
  .btn-holder-right {
    ul li {
      margin-left: 10px;
    }

    @media (max-width: 1389px) {
      ul li a {
        font-size: 13px;
      }

      ul li a img {
        max-width: 16px;
      }
    }
  }

  .title-md {
    @media (max-width: 1024px) { font-size: 12px; }
  }

  .btn-holder-right ul li a {
    @media (min-width: 1390px) { font-size: 18px; }
    @media (max-width: 1290px) { font-size: 13px; }
    @media (max-width: 1024px) { font-size: 11px; }
  }
}

// CUT: from custom
// floating toolbar
.title-holder.hl-sticky.is-sticky {
  right: 0;
  width: auto !important;
  padding: 10px 30px 10px 15px;
  @media (max-width: 768px) { padding: 10px 15px 10px 15px; }
  background: #fff;
  box-shadow: 0 1px 0 #4d4d4d inset, 0 2px 3px rgba(0, 0, 0, 0.25) !important;
  z-index: 999 !important;
}


// CUT: from estimator
admin-trading-estimator {
  .title-holder.hl-sticky.is-sticky {
    padding: 15px 30px 0 15px;
    line-height: 40px;
  }
}

// CUT: from deal-form
admin-trading-new-deal {
  .title-holder.hl-sticky.is-sticky {
    @media (max-width: 768px) {
      left: 0 !important;
    }
  }
}
