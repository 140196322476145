@import './variables';


.btn-link .fa {
    font-size: 16px;
}


.page-cst-st-table {
	padding: 15px 0;
	margin-bottom: 50px;
}

.filters-holder .form-control.searchinput {
	height: 50px;
	line-height: 50px;
}

.search-holder {
    margin-bottom: 20px;
    margin-top: 15px;
}

.filters-holder{
  display: block;
	margin-bottom: 5px;
}
.search-holder .input-group-btn .btn {
    background: #fff;
    border-color: $tc-input-border-color;
    height: 50px;
    border-right: 0;
    margin-bottom: 10px;
}
.search-holder .input-group-btn .btn:focus{
	outline: none;
	box-shadow: none;
}
.search-holder .input-group .form-control.searchinput {
	padding-left: 0;
	border-left: 0;
}
.cost-table-holder{

}

.tc-searchbox {
  label { padding-left: 30px !important; }
  .form-control { padding-left: 35px !important; }

  &::before {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 10px;
    content: '\F002';
    font: normal normal normal 14px/50px 'Font Awesome 6 Pro';
    font-size: 20px;
    text-rendering: auto;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }
}

.ui-grid .btn-tspt,
.cost-table-holder .btn-tspt{
	background: transparent;
	padding: 0 8px;
}
.cost-st-table{
	font-size: 13px;
}
.cost-st-table .fa{
	margin:0 5px;
}
.cost-st-table .icons{
	margin:0 5px;
	position: relative;
	top: -1px;
}
.cost-st-table th{
	font-weight: 400;
}
.cost-st-table small{
	font-size: 12px;
}
.cost-st-table .text-ellipsis {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 130px;
}
.cost-st-table.table>thead>tr>th {
    vertical-align: bottom;
    border-bottom: 1px solid #d1d1d1;
}
.cost-st-table.table>tbody>tr>td, .cost-st-table.table>tbody>tr>th, .cost-st-table.table>tfoot>tr>td,
.cost-st-table.table>tfoot>tr>th, .cost-st-table.table>thead>tr>td, .cost-st-table.table>thead>tr>th {
    padding: 10px 15px;
    line-height: 1.42857143;
    vertical-align: top;
    border-top: 0;
    border-bottom: 1px solid #d1d1d1;
}
.cost-st-table.table .label{
	min-width: 62px;
	text-align: center;
	display: inline-block;
    vertical-align: middle;
    white-space: nowrap;
}
