tc-required-placeholder-input, *[tc-required-placeholder-input]{
  position: relative;
  display: block;
  .special-placeHolder {
    outline: none;
    cursor: text;
    display: flex;
    align-items: center;
    z-index: 1;
    position: absolute;
    left: 0;
    top: 0;
    color: #999;
    font-size: 14px;
    border-radius: 4px;
    line-height: 1.42857;
    height: 50px;
    width: 85%;
    padding: 6px 12px;
    &::after {
      display: inline-block;
      padding-left: 5px;
      color: #ff2d3a;
      content: ' *';
    }
  }
}
