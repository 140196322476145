.tc-table-view {
  .fa-star {
    display: none;
    color: orange;
  }

  &.active {
    // override uib-dropdown
    .dropdown-menu > & > .btn.btn-link { font-weight: bold; }

    &.modified {
      .fa-star { display: inline-block; }
    }
  }
}
