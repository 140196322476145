// TODO: reduce code duplication (see md-date-range and receipts page filters)
.tc-clear-btn {
  display: none;
}

.setField,
.md-input-has-value,
// override md-input-container styles
md-input-container.md-input-has-value {
  .date-icon,
    // override md-input-container styles
   .md-input.date-icon,
   // override custom md-date-range styles
   md-date-range .md-select-value {
    background-image: none;
  }

  label {
    padding-right: 28px !important;
    padding-left: 0;
  }

  .tc-clear-btn {
    display: inline-block;
    position: absolute;
    top: -7px;
    right: -5px;
    margin: 0;

    .fa {
      font-size: 15px;
      line-height: 23px;
      /*opacity: .2; */
      color: #9e9e9e;
      transition: opacity 300ms;
    }
  }

  &:hover .tc-clear-btn .fa {
    opacity: 1;
  }
}
