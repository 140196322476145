.mat-mdc-tooltip {
  &.pre-tooltip {
    white-space: pre-wrap;
    font-family: monospace;
  }

  &.huge-tooltip {
    .mdc-tooltip__surface {
      max-width: unset !important;
      max-height: 50vh;
      overflow-y: auto;
    }
  }
}
