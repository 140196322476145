*[bs-loading-overlay] {
  position: relative;

  .overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.2);
    text-align: center;
    .loading {
      position: absolute;
      top: 20%;
      font-size: 30px;
    }
  }
}
