@import 'src/assets/styles/mixins';


tc-matched-offers {
  @extend %tc-page-with-sticky-header;
  @extend %tc-fix-ui-grid-header;

  .dropdown-menu-cell .ui-grid-cell-contents {
    padding: 0;
  }
}
