// colors
$tc-red: #ff2d3a;
$tc-darkred: darken($tc-red, 30);
$tc-white: #fff;
$tc-blue: #66afe9;
$tc-black: #202020;

// theme
$tc-input-border-color: #ddd;
$tc-input-border: 1px solid $tc-input-border-color;
$tc-input-border-radius: 4px;
$tc-input-font-size: 14px;

$tc-label-font-color: #969696;
$tc-label-font-size: 12px;
$tc-placeholder-font-size: $tc-input-font-size;

$tc-input-readonly-background: #fafafa;

$tc-placeholder-color: #999;
$tc-invalid-border-color: $tc-red;
$tc-invalid-placeholder-color: $tc-darkred;

$tc-input-font-size: 16px !default;

$tc-grid-color: $tc-black;
$tc-grid-font-size: 13px;

$tc-grid-header-color: #919191;
$tc-grid-color: #555;

$tc-navbar-height: var(--tc-header-height);
