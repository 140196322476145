@use '@angular/material' as mat;
@import 'sass-recursive-map-merge/recursive-map-merge';

$nav-menu-white: #fff !default;
$nav-menu-blue: #4899ee !default;

$nav-menu-foreground: (
  disabled: rgba($nav-menu-white, 0.5),
  icon: $nav-menu-white,
  text: $nav-menu-white,
) !default;

$nav-menu-background: (
  hover: $nav-menu-white,
  card: $nav-menu-blue,
) !default;

$override: (
  foreground: $nav-menu-foreground,
  background: $nav-menu-background,
  color: (
    foreground: $nav-menu-foreground,
    background: $nav-menu-background
  )
) !default;

@mixin nav-menu-theme($theme-or-color-config) {
  $dedupe-key: 'nav-menu-theme';

  $nav-menu-theme: recursive-map-merge($theme-or-color-config, $override);

  @include mat.menu-color($nav-menu-theme);

  // override active/hover color (@angular/material has no special theme var for text color)
  .mat-mdc-menu-item:hover,
  .mat-mdc-menu-item.mat-mdc-menu-item.cdk-focused,
  .mat-mdc-menu-item.mat-mdc-menu-item-highlighted {
    &:not([disabled]) {
      background: $nav-menu-white;
      color: $nav-menu-blue;
    }
  }
}

