@import '../variables';


md-input-container.tc-md-block {
  margin: 0;
  padding: 0;

  label {
    width: calc(100% - 10px);
    color: $tc-label-font-color;
    font-size: $tc-placeholder-font-size;
    font-weight: normal;

    &:not(.md-no-float):not(.md-container-ignore) {
      width: calc(100% - 10px);
      transform: translate3d(10px, 40px, 0) scale(1);
      transition: transform .4s cubic-bezier(.25, .8, .25, 1);
    }

    &.md-required::after {
      color: $tc-red !important;
    }
  }

  &.md-input-focused label:not(.md-no-float),
  &.md-input-has-placeholder label:not(.md-no-float),
  &.md-input-has-value label:not(.md-no-float) {
    // compensate scale
    width: calc(110%);
    max-width: calc(110%);
    // move up and scale
    transform: translate3d(10px, 30px, 0) scale(.8);
    transition: transform cubic-bezier(.25, .8, .25, 1) .4s, width cubic-bezier(.25, .8, .25, 1) .4s;
  }

  &.md-input-focused {
    &:not(.md-input-invalid) {
      .md-input {
        border-color: $tc-blue;
      }
    }

    .md-input {
      padding-bottom: 6px;
      border: 1px solid $tc-blue;
      box-shadow: inset 0 1px 1px rgba(0, 0, 0, .075), 0 0 8px rgba(102, 175, 233, .6);

      &.ng-invalid.ng-dirty {
        border: 1px solid;
      }
    }
  }

  .md-input {
    &.ng-invalid.ng-dirty {
      border: 1px solid;
    }
  }

  .form-control {
    height: 50px;
    padding: 24px 10px 6px 12px;
    border: 1px solid $tc-input-border-color;
    border-radius: 4px;
    background-color: $tc-white;

    // span {
    &:not(input):not(textarea) {
      // this supposed to hide readonly values (like payment terms on shipping loge details)
      text-overflow: ellipsis;
      white-space: nowrap;
      overflow: hidden;
    }
  }

  input.md-input {
    margin: 0;
  }

  .md-errors-spacer {
    min-height: 0;
  }

  &.clearable {
    position: relative;
    .clear-btn {
      position: absolute;
      right: 10px;
      top: 40%;
      color: #969696;
    }
  }
}
