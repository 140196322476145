// @import '@angular/material/theming';

.mat-mdc-raised-button:not([class*=mat-elevation-z]) {
  // box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12);
  // @include mat-elevation(2, #ddd, 0.3);
  box-shadow: 2px 2px 6px 1px #ddd;
}

.mat-mdc-button,
.mat-mdc-raised-button {
  --mdc-text-button-label-text-transform: uppercase;
  --mdc-protected-button-label-text-transform: uppercase;
  font-size: 13px;
  font-weight: 400;

  .mat-icon {
    height: initial;
    vertical-align: initial !important;
  }
}

.mdc-button__label:has(.fa, .far, .fal, .fas, .fa-brands) {
  display: flex;
  gap: 10px;
  align-items: center;

  .fa,
  .fal,
  .far,
  .fa-brands,
  .fas {
    font-size: 14px;
    &.fa-20 { font-size: 20px; }
  }
}

// NOTE: with .mat-button-wrapper { display: flex } we broke mat-datepicker calendar header.
mat-custom-header.type-date .mat-custom-controls .mat-custom-date-monthday:after {
  // content: ", ";
  position: absolute;
  right: -0.1em;
  top: 0;
}


@mixin defineButtonColor($color-name, $bg-color, $text-color) {
  &.mat-#{$color-name} {
    background-color: $bg-color;
    color: $text-color;

    &:not([disabled]):hover {
      background-color: darken($bg-color, 10%);
    }
  }
}

.mat-mdc-mini-fab:not(:disabled),
.mat-mdc-raised-button:not(:disabled) {
  @include defineButtonColor('add', #58c264, white);
  @include defineButtonColor('action', #f0ad4e, white);
  @include defineButtonColor('primary', #3998ea, white);
}

.mat-mdc-button {
  font-weight: 500;

  &:hover,
  &:focus {
    text-decoration: none;
  }

  &:not(:disabled).mat-link {
    color: #505050;

    &:hover,
    &:focus {
      color:#3998ea;
      outline: none; // overrides bootstrap scaffolding.scss styles
    }
  }
}

// Handle buttons appropriately, with respect to line-height
.mat-mdc-raised-button,
.mat-mdc-outlined-button,
.mat-mdc-unelevated-button {
  padding: 0 1.15em;
  min-width: 3em;
  line-height: 36.4px
}

.mat-mdc-raised-button,
.mat-mdc-outlined-button,
.mat-mdc-unelevated-button {

  +.mat-mdc-raised-button,
  +.mat-mdc-outlined-button,
  +.mat-mdc-unelevated-button {
    margin-left: 1em;
  }
}

.mat-mdc-icon-button .mat-icon {
  display: grid;
  align-content: center;
}
