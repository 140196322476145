@use 'sass:map';
@use '@angular/material' as mat;
@use '../../core/tokens/token-utils';
@use '../../core/tokens/m2/mtx/grid' as tokens-mtx-grid;

$_tokens: tokens-mtx-grid.$prefix, tokens-mtx-grid.get-token-slots();

// Required for resizing to work properly.
.mat-column-resize-table.cdk-column-resize-with-resized-column {
  table-layout: fixed;
}

.mat-column-resize-flex {
  .mat-mdc-header-cell,
  .mat-mdc-cell {
    box-sizing: border-box;
    min-width: 32px;
  }
}

.mat-mdc-header-cell {
  position: relative;
}

.mat-resizable {
  box-sizing: border-box;
}

.mat-mdc-header-cell:not(.mat-resizable)::after,
.mat-resizable-handle {
  position: absolute;
  bottom: 0;
  top: 0;
  right: 0;
  width: 1px;
  background: transparent;
  transition: background mat.$private-swift-ease-in-duration mat.$private-swift-ease-in-timing-function;
}

.mat-mdc-header-cell:not(.mat-resizable)::after {
  content: '';
}

[dir='rtl'] {
  .mat-mdc-header-cell:not(.mat-resizable)::after,
  .mat-resizable-handle {
    left: 0;
    right: auto;
  }

  .mat-mdc-header-row.cdk-column-resize-hover-or-active {
    .mat-header-cell,
    .mat-mdc-header-cell {
      border-left: none;
    }
  }
}

.mat-mdc-header-row.cdk-column-resize-hover-or-active {
  .mat-mdc-header-cell {
    border-right: none;
  }

  @include token-utils.use-tokens($_tokens...) {
    .mat-mdc-header-cell:not(.mat-resizable)::after {
      @include token-utils.create-token-slot(background-color, resizable-handle-disabled-background-color);
    }

    .mat-resizable-handle {
      @include token-utils.create-token-slot(background-color, resizable-handle-hover-background-color);
    }
  }
}

.mat-resizable.cdk-resizable-overlay-thumb-active>.mat-resizable-handle {
  opacity: 0;
  transition: none;
}

.mat-resizable-handle:focus,
.mat-mdc-header-row.cdk-column-resize-hover-or-active .mat-resizable-handle:focus {
  outline: none;

  @include token-utils.use-tokens($_tokens...) {
    @include token-utils.create-token-slot(background-color, resizable-handle-active-background-color);
  }
}

.mat-column-resize-overlay-thumb {
  background: transparent;
  cursor: col-resize;
  width: 100%;
  height: 100%;
  transition: background mat.$private-swift-ease-in-duration mat.$private-swift-ease-in-timing-function;

  @include mat.private-user-select(none);

  &:active {
    will-change: transform;

    @include token-utils.use-tokens($_tokens...) {
      $background-color: token-utils.get-token-variable(resizable-handle-active-background-color);

      background: linear-gradient(90deg,
          transparent, transparent 7px,
          var($background-color) 7px, var($background-color) 9px,
          transparent 9px, transparent);

      .mat-column-resize-overlay-thumb-top {
        background: linear-gradient(90deg,
            transparent, transparent 4px,
            var($background-color) 4px, var($background-color) 12px,
            transparent 12px, transparent);
      }
    }
  }
}

.mat-column-resize-overlay-thumb-top {
  width: 100%;
}
