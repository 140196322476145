// This is for columns editor dialog (the only place where we have dnd-list-wrapper)
.dnd-list-wrapper {
  padding-bottom: 20px;
}

/**
 * The dnd-list should always have a min-height,
 * otherwise you can't drop into it once it's empty
 */
.dnd-list-wrapper {
  ul {
    min-height: 42px;
    height: 300px;
    overflow: auto;
    border: 1px solid #cecece;
    border-radius: 5px;
    padding: 0;
    list-style: none;
    background: #fff;
  }

  li {
    display: block;
    padding: 10px 15px;
    color: #555;

    &.selected {
      background-color: #f3f8fd;
    }

    .name {
      display: inline-block;
      margin-left: 5px;
    }
  }

  /**
   * An element with .dndPlaceholder class will be
   * added to the dnd-list while the user is dragging
   * over it.
   */
  .dndPlaceholder {
    background-color: #ddd;
    display: block;
    min-height: 42px;
  }
}
