.skeleton-loader {
  box-sizing: border-box;
  background: rgb(239, 241, 246) no-repeat;
  overflow: hidden;
  position: relative;
  border-radius: 5px;

  &.thin-line,
  &.tiny-line {
    width: 80%;
  }

  &.thin-line + &.tiny-line {
    margin-top: 10px;
  }

  &.thin-line {
    height: 15px;
  }

  &.tiny-line {
    height: 10px;
  }

  &.square {
    height: 20px;
    width: 20px;
  }

  &.circle {
    height: 30px;
    width: 30px;
    border-radius: 50%;
  }

  &.animated {
    &::after {
      box-sizing: border-box;
      content: '';
      display: block;
      position: absolute;
      left: -20px;
      top: 0;
      height: 100%;
      right: -20px;
      will-change: transform;
      background: linear-gradient(to right, transparent 0%, white 10px, transparent 20px);
      animation: glare 1s cubic-bezier(0.4, 0.0, 0.2, 1) infinite;
    }
  }
}

@keyframes glare {
  from {
    transform: translate3d(0, 0, 0);
  }

  to {
    transform: translate3d(100%, 0, 0);
  }
}
