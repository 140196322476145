@import '../variables';
@import '../animations';

%box-control {
  margin-bottom: 10px;
  border-color: $tc-input-border-color;
  color: #202020;
  box-shadow: none;
  
  &:not(textarea) { height: 50px; }

  @media (min-width: 1390px) { font-size: 14px; }
  // @media (max-width: 1389px) { font-size: $tc-input-font-size; }
  @media (max-width: 768px) { margin-bottom: 15px; }

  // invalid state
  .ng-submitted &.ng-invalid,
  &.ng-invalid.ng-dirty,
  &.ng-invalid.ng-touched {
    @include shake();

    border-color: $tc-invalid-border-color;
  }

  &[readonly] {
    background-color: $tc-input-readonly-background;
    cursor: initial;
  }

  &select {
    background: #fff url('../images/icons/select-custom-arrow.png') no-repeat right 15px center / 12px auto;
    appearance: none;
  }

  .setField &,
  .setField &select {
    padding: 23px 10px 6px;
  }
}
