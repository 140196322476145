.split-title-wrapper {
  position: relative;
  .split {

  }
  .split-title {
    position: absolute;
    top: -8px;
    left: 35px;
    background: #fafafa;
    display: inline-block;
    padding: 0 10px;
    font-size: 16px;
    margin: 0;
  }
}
