
/* You can add global styles to this file, and also import other style files */
@import "assets/styles/material/index.scss";
@import "assets/styles/index.scss";

// @import "angularjs-toaster/toaster.scss";
@import "src/assets/js/redactor/redactor.scss";

@import "src/components/app/app.component.scss";
@import "src/components/breadcrumbs/breadcrumbs.component.scss";
@import "src/components/grid/grid.component.scss";
@import "src/components/grid/pagination/grid-pagination.component.scss";
@import "src/components/grid/views-menu/views-menu.component.scss";
@import "src/components/select/select.component.scss";
@import "src/components/table-view/configure-columns/configure-columns.component.scss";
@import "src/directives/epoch-date-picker/epoch-date-picker.directive.scss";
@import "src/directives/file-select-wrap/file-select-wrap.directive.scss";
@import "src/directives/redactor/redactor.directive.scss";
@import "src/directives/required-placeholder-input/required-placeholder-input.scss";
@import "src/directives/sticky/sticky.directive.scss";
@import "src/pages/admin/auction/matched-offers/matched-offers.component.scss";
@import "src/pages/admin/auction/supplier-offer-overlay/supplier-offer-clone-form/supplier-offer-clone-form.component.scss";
@import "src/pages/admin/auction/supplier-offers/supplier-offers.component.scss";
@import "src/pages/admin/financial/credit-notes/credit-notes.component.scss";
@import "src/pages/admin/financial/receipts/form/receipt-apply-payment-form.component.scss";
@import "src/pages/admin/logistics/credit-notes/credit-notes.component.scss";
@import "src/pages/admin/logistics/shipping-logs/detail/overlays/package-tracking/package-tracking.component.scss";
@import "src/pages/admin/logistics/shipping-logs/detail/overlays/shipment-tracking/shipment-tracking.component.scss";
@import "src/pages/admin/logistics/transportations/transportations.component.scss";
@import "src/pages/admin/management/credit-notes/credit-notes.component.scss";
@import "src/pages/admin/notification/feeds/form/message-view-form.component.scss";
@import "src/pages/admin/settings/companies/companies/form/company-advanced-search-form.component.scss";
@import "src/pages/admin/settings/custom-costs/custom-costs.component.scss";
@import "src/pages/admin/settings/document-library/sets/form/document-set-form.component.scss";
@import "src/pages/admin/settings/templates/form/template-form.component.scss";
@import "src/pages/admin/trading/deal-form/deal-shipping/shipment-rate-picker/shipment-rate-picker.component.scss";
@import "src/templates/loading-overlay.scss";

html, body {
  min-height: 100%;
  height: 100%;
}
body { margin: 0; font-family: Roboto, "Helvetica Neue", sans-serif; }

.label-pending {
  background-color: #e98d38;
}

.tc-archived {
  color: lightgray !important;
}
